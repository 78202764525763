<template>
    <div>
        <h2>FAQ 상세보기</h2>
        <div v-if="faq">
            <h3>{{ faq.title }}</h3>
            <div v-html="faq.content"></div>
        </div>
        <div v-else>
            <p>해당 FAQ를 찾을 수 없습니다.</p>
        </div>
        <button @click="goBack">목록으로 돌아가기</button>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'FaqDetail',

    data() {
        return {
            faq: null, // FAQ 데이터를 저장할 객체
            API_BASE_URL: process.env.VUE_APP_API_BASE_URL, // API URL
            id: this.$route.params.id,
        };
    },
    mounted() {
        this.fetchFaq(); // 컴포넌트가 마운트될 때 FAQ 데이터를 가져옴

    },
    methods: {
        async fetchFaq() {
            try {
                console.log(this.id);
                const response = await axios.get(`${this.API_BASE_URL}/faq/${this.id}`);
                this.faq = response.data.data;

            } catch (error) {
                console.error('Error fetching FAQ:', error);
            }
        },
        goBack() {
            this.$router.push({ name: 'faqlist' }); // 목록 페이지로 돌아가기
        },
    },
};
</script>
