<template>
    <div>
        <h2>공지사항 관리</h2>
        <button @click="goToAddNotice">공지사항 추가</button>
        <table>
            <thead>
                <tr>
                    <th>번호</th>
                    <th>제목</th>
                    <th>작성자</th>
                    <th>등록일</th>
                    <th>조회수</th>
                    <th>수정</th>
                    <th>삭제</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(notice, index) in notices" :key="notice.id">
                    <td>{{ index + 1 }}</td>
                    <td><a :href="`/noticedetail/${notice.id}`">{{ notice.title }}</a></td>
                    <td>{{ notice.author }}</td>
                    <td>{{ formatDate(notice.created_at) }}</td>
                    <td>{{ notice.views }}</td>
                    <td><button @click="editNotice(notice.id)">수정</button></td>
                    <td><button @click="deleteNotice(notice.id)">삭제</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'AdminNoticeList',
    data() {
        return {
            notices: [], // 공지사항 데이터 배열
            API_BASE_URL: process.env.VUE_APP_API_BASE_URL, // API URL
        };
    },
    mounted() {
        this.fetchNotices(); // 컴포넌트가 마운트될 때 공지사항 데이터 가져옴
    },
    methods: {
        async fetchNotices() {
            try {
                const response = await axios.get(`${this.API_BASE_URL}/notice`);
                if (response.data.success) {
                    this.notices = response.data.data;
                } else {
                    alert('공지사항 조회 실패');
                }
            } catch (error) {
                console.error('공지사항 조회 중 에러 발생:', error);
                alert('공지사항 조회 중 에러가 발생했습니다.');
            }
        },
        goToAddNotice() {
            this.$router.push({ name: 'notice_insert' }); // 공지사항 추가 페이지로 이동
        },
        editNotice(id) {
            this.$router.push({ name: 'notice_edit', params: { id } }); // 공지사항 수정 페이지로 이동
        },
        async deleteNotice(id) {
            if (confirm('정말로 이 공지사항을 삭제하시겠습니까?')) {
                try {
                    const response = await axios.delete(`${this.API_BASE_URL}/notice/${id}`);
                    if (response.data.success) {
                        alert('공지사항이 성공적으로 삭제되었습니다.');
                        this.fetchNotices(); // 목록 갱신
                    } else {
                        alert('공지사항 삭제 실패');
                    }
                } catch (error) {
                    console.error('공지사항 삭제 중 에러 발생:', error);
                    alert('공지사항 삭제 중 에러가 발생했습니다.');
                }
            }
        },
        formatDate(dateString) {
            return new Date(dateString).toLocaleDateString();
        },
    },
};
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

button {
    padding: 5px 10px;
    margin: 5px;
}
</style>
