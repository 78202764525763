<template>
    <div>
        <CarouselPage />
    </div>
</template>

<script>
import CarouselPage from '../section/CarouselPage.vue';

export default {
    name: 'MainPage',
    components: {
        CarouselPage
    }
}
</script>

<style></style>
