<template>
    <div>
        <subbar />
        <boardsearch @search="handleSearch" />
        <div class="sub_menu">
            <ul>
                <li v-for="item in categories" :key="item.id"><a :href="item.link">{{ item.title }}</a></li>
            </ul>
        </div>
        <Data :filteredItems="filteredItems" />
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import subbar from '../../components/section/subbar.vue';
import Data from '../../components/data/data.vue';
import boardsearch from '../../components/section/boardsearch.vue';

export default {
    name: 'FriNotice',
    components: {
        subbar,
        Data,
        boardsearch
    },
    data() {
        return {
            categories: [], // 카테고리 데이터를 저장
            items: [], // 게시글 데이터를 저장
            filteredItems: [],
            searchField: 'title',
            searchKeyword: '',
            categoryCode: null,
            parentid: null,
            itemslength: "",
        };
    },
    mounted() {
        this.fetchCategories(); // 카테고리 데이터를 가져옵니다.
        this.fetchData(); // 게시글 데이터를 가져옵니다.
        this.updatebtncolor();

    },
    methods: {
        async fetchCategories() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                this.parentid = urlParams.get('parent');
                this.categoryCode = urlParams.get('category_code');

                const response = await axios.get(`${API_BASE_URL}/navigation/sub2-navigation`, {
                    params: { parentId: this.parentid }
                });

                if (response.data.success) {
                    this.categories = response.data.data; // categories에 카테고리 데이터를 저장
                    console.log(this.categories, "cate");

                    // 카테고리 데이터를 불러온 후에 updateItemWidths를 호출
                    this.updateItemWidths();
                } else {
                    alert('카테고리 조회 실패');
                }
            } catch (error) {
                console.error('카테고리 조회 중 에러 발생:', error);
                alert('카테고리 조회 중 에러가 발생했습니다.');
            }
        },

        async fetchData() {
            try {
                const response = await axios.get(`${API_BASE_URL}/data`, {
                    params: {
                        category: this.categoryCode,
                        search_field: this.searchField,
                        search_keyword: this.searchKeyword
                    }
                });

                if (response.data.success) {
                    this.items = response.data.data; // items에 게시글 데이터를 저장
                    this.itemslength = this.items.length;
                    this.updateItemWidths();
                    this.filteredItems = this.items; // 필터링된 데이터를 초기화
                } else {
                    alert('게시글 조회 실패');
                }
            } catch (error) {
                console.error('게시글 조회 중 에러 발생:', error);
                alert('게시글 조회 중 에러가 발생했습니다.');
            }
        },

        handleSearch(searchParams) {
            this.searchField = searchParams.field;
            this.searchKeyword = searchParams.keyword;
            this.fetchData(); // 검색 조건에 따라 게시글 데이터를 다시 가져옵니다.
        },

        updateItemWidths() {
            const navItemsCount = this.categories.length;
            console.log(navItemsCount, "nav");
            const widthPercentage = 100 / navItemsCount;
            console.log(widthPercentage);

            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `
                .sub_menu ul li {
                    width: ${widthPercentage}%;
                }
            `;
            document.head.appendChild(style);
        },

        updatebtncolor() {
            const urlParams = new URLSearchParams(window.location.search);
            this.categoryCode = urlParams.get('category_code');
            console.log(this.categoryCode);
            const style = document.createElement('style');
            style.type = 'text/css';
            if (this.categoryCode === null) {
                style.innerHTML = `
                    .sub_menu ul li:nth-child(1) {
                        background-color: #ff6600;
                        color: white !important;
                    }
                    .sub_menu ul li:nth-child(1) > a {
                        color: white !important;
                    }
                `;
            } else {
                style.innerHTML = `
                    .sub_menu ul li:nth-child(${this.categoryCode}) {
                        background-color: #ff6600;
                        color: white !important;
                    }
                    .sub_menu ul li:nth-child(${this.categoryCode}) > a {
                        color: white !important;
                    }
                `;
            }
            document.head.appendChild(style);
        }




    }
};
</script>

<style>
@import "../../css/frinotice.css";
@import "../../css/notice.css";
@import "../../css/pcsize.css";
</style>
