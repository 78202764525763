<template>
    <div>
        <h1>{{ lecture.course_name }} - 수강 차시 관리</h1>
        <button @click="openModal()">차시 추가</button>
        <table>
            <thead>
                <tr>
                    <th>번호</th>
                    <th>콘텐츠 이름</th>
                    <th>콘텐츠 링크</th>
                    <th>제한 시간 (분)</th>
                    <th>생성일</th>
                    <th>액션</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="content in lectureContents" :key="content.id">
                    <td>{{ content.num }}</td>
                    <td>{{ content.content_name }}</td>
                    <td><a :href="content.content_link" target="_blank">{{ content.content_link }}</a></td>
                    <td>{{ content.limit_time }}</td>
                    <td>{{ content.created_at }}</td>
                    <td>
                        <button @click="openEditModal(content)">수정</button>
                        <button @click="deleteContent(content.id)">삭제</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- 추가/수정 모달 -->
        <div v-if="isModalOpen" class="modal_overlay" @click="closeModal">
            <div class="modal_content" @click.stop>
                <h3 v-if="isEditing">차시 수정</h3>
                <h3 v-else>차시 추가</h3>
                <form @submit.prevent="isEditing ? updateLectureContent() : submitLectureContent()">
                    <div>
                        <label>차시 번호:</label>
                        <input type="number" v-model="currentContent.num" required />
                    </div>
                    <div>
                        <label>콘텐츠 이름:</label>
                        <input type="text" v-model="currentContent.content_name" required />
                    </div>
                    <div>
                        <label>콘텐츠 링크:</label>
                        <input type="text" v-model="currentContent.content_link" required />
                    </div>
                    <div>
                        <label>제한 시간 (분):</label>
                        <input type="number" v-model="currentContent.limit_time" required />
                    </div>
                    <button type="submit">{{ isEditing ? '수정' : '저장' }}</button>
                    <button @click="closeModal">취소</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'LectureContent',
    data() {
        return {
            lecture: {},
            lectureContents: [],
            isModalOpen: false,
            isEditing: false,
            currentContent: {
                id: null,
                num: '',
                content_name: '',
                content_link: '',
                limit_time: ''
            }
        };
    },
    methods: {
        async fetchLecture() {
            try {
                const courseId = this.$route.query.id;
                const token = localStorage.getItem('accessToken');
                const response = await axios.post(`${API_BASE_URL}/lecture/lecture/courses`, {
                    course_ids: [courseId],
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.length > 0) {
                    this.lecture = response.data[0];
                    this.fetchLectureContents();
                } else {
                    alert('해당 강의를 찾을 수 없습니다.');
                }
            } catch (error) {
                console.error('강의 상세 조회 중 에러 발생:', error);
                alert('강의 상세 조회 중 오류가 발생했습니다.');
            }
        },
        async fetchLectureContents() {
            try {
                const courseId = this.$route.query.id;
                const token = localStorage.getItem('accessToken');
                const response = await axios.get(`${API_BASE_URL}/lecture/lecture_contents?lecture_id=${courseId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.success) {
                    this.lectureContents = response.data.data.sort((a, b) => a.num - b.num);
                } else {
                    alert('수강 차시 목록을 불러오는 중 오류가 발생했습니다.');
                }
            } catch (error) {
                console.error('수강 차시 목록 조회 중 에러 발생:', error);
                alert('수강 차시 목록 조회 중 오류가 발생했습니다.');
            }
        },
        openModal() {
            this.isEditing = false;
            this.resetCurrentContent();
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        openEditModal(content) {
            this.isEditing = true;
            this.currentContent = { ...content };
            this.isModalOpen = true;
        },
        resetCurrentContent() {
            this.currentContent = {
                id: null,
                num: '',
                content_name: '',
                content_link: '',
                limit_time: ''
            };
        },
        async submitLectureContent() {
            try {
                const token = localStorage.getItem('accessToken');
                const lectureId = this.lecture.id;
                const response = await axios.post(`${API_BASE_URL}/lecture/lecture_contents`, {
                    lecture_id: lectureId,
                    num: this.currentContent.num,
                    content_name: this.currentContent.content_name,
                    content_link: this.currentContent.content_link,
                    limit_time: this.currentContent.limit_time
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.success) {
                    alert('차시가 성공적으로 추가되었습니다.');
                    this.closeModal();
                    this.fetchLectureContents();
                } else {
                    alert('차시 추가 중 오류가 발생했습니다.');
                }
            } catch (error) {
                console.error('차시 추가 중 에러 발생:', error);
                alert('차시 추가 중 오류가 발생했습니다.');
            }
        },
        async updateLectureContent() {
            try {
                const token = localStorage.getItem('accessToken');
                const response = await axios.put(`${API_BASE_URL}/lecture/lecture_contents/${this.currentContent.id}`, {
                    num: this.currentContent.num,
                    content_name: this.currentContent.content_name,
                    content_link: this.currentContent.content_link,
                    limit_time: this.currentContent.limit_time
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.success) {
                    alert('차시가 성공적으로 수정되었습니다.');
                    this.closeModal();
                    this.fetchLectureContents();
                } else {
                    alert('차시 수정 중 오류가 발생했습니다.');
                }
            } catch (error) {
                console.error('차시 수정 중 에러 발생:', error);
                alert('차시 수정 중 오류가 발생했습니다.');
            }
        },
        async deleteContent(contentId) {
            if (!confirm('정말로 이 차시를 삭제하시겠습니까?')) return;

            try {
                const token = localStorage.getItem('accessToken');
                const response = await axios.delete(`${API_BASE_URL}/lecture/lecture_contents/${contentId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.success) {
                    alert('차시가 성공적으로 삭제되었습니다.');
                    this.fetchLectureContents();
                } else {
                    alert('차시 삭제 중 오류가 발생했습니다.');
                }
            } catch (error) {
                console.error('차시 삭제 중 에러 발생:', error);
                alert('차시 삭제 중 오류가 발생했습니다.');
            }
        }
    },
    mounted() {
        this.fetchLecture();
    },
};
</script>

<style scoped>
@import "../../../css/lectureinsert.css";
/* 기존에 사용하던 CSS 파일을 그대로 재사용합니다 */

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f4f4f4;
}

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal_content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 500px;
}

.modal_content form div {
    margin-bottom: 15px;
}

.modal_content label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.modal_content input[type="text"],
.modal_content input[type="number"] {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

button+button {
    margin-left: 10px;
    background-color: #6c757d;
}

button+button:hover {
    background-color: #5a6268;
}
</style>
