<template>
    <div class="container">
        <h1 class="title"></h1>

        <!-- 티커 입력 필드 -->
        <div class="input-container">
            <label for="ticker" class="label">티커 입력: </label>
            <input v-model="ticker" id="ticker" class="ticker-input" placeholder="예: 005930.KS" />
            <button @click="fetchTradeSignal" class="fetch-button">신호 가져오기</button>
        </div>

        <!-- 로딩 상태 -->
        <div v-if="loading" class="loading">로딩 중...</div>

        <!-- 현재 가격 및 신호 -->
        <div v-else class="info-container">
            <p class="info-text">현재 가격: <span class="highlight">{{ currentPrice }}</span></p>
            <p class="info-text">매매 신호: <span class="highlight">{{ tradeSignal }}</span></p>
        </div>

        <!-- 매매 신호 내역 -->
        <h2 class="history-title">매매 신호 내역</h2>
        <ul class="history-list">
            <li v-for="(log, index) in signalHistory" :key="index" class="history-item">
                {{ log.time }} - {{ log.signal }} (가격: {{ log.price }})
            </li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'TradeSignal',
    data() {
        return {
            ticker: '',  // 사용자가 입력한 티커 값
            currentPrice: null,
            tradeSignal: null,
            loading: false,
            signalHistory: []  // 매매 신호 내역을 저장할 배열
        };
    },
    methods: {
        async fetchTradeSignal() {
            if (!this.ticker) {
                alert("티커를 입력하세요.");
                return;
            }

            this.loading = true;
            try {
                // 사용자 입력 티커를 사용하여 API 호출
                const response = await axios.get(`${API_BASE_URL}/trade`, {
                    params: { ticker: this.ticker }
                });
                const newPrice = response.data.current_price;
                const newSignal = response.data.signal === 'buy' ? '매수' : '매도';

                // 가격이 변경된 경우에만 내역에 추가
                if (newPrice !== this.currentPrice) {
                    this.currentPrice = newPrice;
                    this.tradeSignal = newSignal;

                    // 신호 내역 저장 (시간 포함)
                    const now = new Date().toLocaleString();  // 현재 시간 기록
                    this.signalHistory.unshift({
                        time: now,
                        signal: this.tradeSignal,
                        price: this.currentPrice
                    });

                    // 신호 내역이 20개를 초과하면 가장 오래된 기록을 삭제
                    if (this.signalHistory.length > 20) {
                        this.signalHistory.pop();
                    }
                }

            } catch (error) {
                console.error("API 호출 중 오류 발생:", error);
            } finally {
                this.loading = false;
            }
        }
    },
    beforeUnmount() {
        // 컴포넌트가 사라질 때 setInterval을 정리
        clearInterval(this.interval);
    }
};
</script>

<style scoped>
/* 공통 스타일 */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
}

.container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #333;
}

.input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.label {
    font-weight: bold;
    margin-right: 10px;
}

.ticker-input {
    flex-grow: 1;
    padding: 8px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
}

.fetch-button {
    padding: 8px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.fetch-button:hover {
    background-color: #0056b3;
}

.loading {
    text-align: center;
    font-size: 1.2em;
    color: #666;
}

.info-container {
    margin-bottom: 20px;
    text-align: center;
}

.info-text {
    font-size: 1.2em;
    margin: 10px 0;
}

.highlight {
    font-weight: bold;
    color: #007bff;
}

.history-title {
    font-size: 1.2em;
    margin-bottom: 10px;
    color: #333;
}

.history-list {
    list-style-type: none;
    padding: 0;
}

.history-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 0.9em;
    color: #555;
}

/* 반응형 디자인 */
@media (max-width: 768px) {
    .container {
        padding: 15px;
    }

    .input-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .ticker-input {
        margin-right: 0;
        margin-bottom: 10px;
        width: 100%;
    }

    .fetch-button {
        width: 100%;
        padding: 10px;
        font-size: 1.1em;
    }

    .info-text {
        font-size: 1.1em;
    }

    .history-item {
        font-size: 1em;
    }
}
</style>
