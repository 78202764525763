<template>
    <div>
        <h2>{{ material.title }}</h2>
        <p><strong>작성자:</strong> {{ material.author }}</p>
        <p><strong>등록일:</strong> {{ formatDate(material.created_at) }}</p>
        <p><strong>조회수:</strong> {{ material.views }}</p>
        <div v-html="material.content"></div>
        <div v-if="material.file">
            <p><strong>첨부 파일:</strong> <a :href="`${API_BASE_URL}/data/download/${material.file}`">{{
                material.file }}</a></p>
        </div>
        <button @click="goBack">목록으로 돌아가기</button>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'StudyMaterialDetail',
    data() {
        return {
            material: {},
            API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
        };
    },
    mounted() {
        this.fetchMaterial();
    },
    methods: {
        async fetchMaterial() {
            try {
                const response = await axios.get(`${this.API_BASE_URL}/data/detail`, {
                    params: { id: this.$route.params.id }
                });
                this.material = response.data.data;
            } catch (error) {
                console.error('Error fetching material:', error);
            }
        },
        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            };
            return new Date(dateString).toLocaleString('ko-KR', options);
        },
        goBack() {
            this.$router.push({ name: 'StudyMaterialList' });
        },
    },
};
</script>

<style scoped>
button {
    padding: 10px 20px;
    margin-top: 20px;
}
</style>
