<template>
    <div>
        <h2>{{ notice.title }}</h2>
        <div>
            <p>작성자: {{ notice.author }}</p>
            <p>작성일: {{ formatDate(notice.created_at) }}</p>
            <p>조회수: {{ notice.views }}</p>
        </div>
        <div v-html="notice.content"></div>

        <div v-if="notice.file">
            <p>첨부파일: <a :href="fileDownloadUrl" download>{{ notice.file }}</a></p>
        </div>

        <button @click="goBack">목록으로 돌아가기</button>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'NoticeDetail',
    data() {
        return {
            notice: {},
            API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
        };
    },
    computed: {
        fileDownloadUrl() {
            return `${this.API_BASE_URL}/notice/download/${this.notice.file}`;
        },
    },
    mounted() {
        this.fetchNoticeDetail();
    },
    methods: {
        async fetchNoticeDetail() {
            try {
                const response = await axios.get(`${this.API_BASE_URL}/notice/detail`, {
                    params: { id: this.$route.params.id },
                });

                if (response.data.success) {
                    this.notice = response.data.data;
                } else {
                    alert('공지사항을 불러오지 못했습니다.');
                }
            } catch (error) {
                console.error('공지사항 상세 조회 중 에러 발생:', error);
                alert('공지사항을 불러오는 중 오류가 발생했습니다.');
            }
        },
        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            };
            return new Date(dateString).toLocaleString('ko-KR', options).replace(/\./g, '-').replace(/ /g, ' ').replace(/-/g, '-');
        },
        goBack() {
            this.$router.push({ name: 'notice_list' });
        },
    },
};
</script>

<style scoped>
h2 {
    margin-bottom: 10px;
}

p {
    margin: 5px 0;
}

button {
    margin-top: 20px;
    padding: 10px 20px;
    cursor: pointer;
}
</style>
