import { createApp } from 'vue'
import App from './App.vue'
import router from './routes/index.js';
import adminRouter from './routes/index2.js';



const app = createApp(App);
const host = window.location.host
const parts = host.split('.')
let selectedRouter;

if (parts[0] === 'admin') {
    console.log('Using Admin Router');
    selectedRouter = adminRouter;
} else {
    selectedRouter = router
}

app.use(selectedRouter);
app.mount('#app');
