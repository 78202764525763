<template>
    <div>
        <subbar />
        <div class="inner_warn">
            <div class="edu_mo">
                <ul class="edu_mode">
                    <li @click="setActiveTab('ing')" :class="{ active: activeTab === 'ing' }">
                        진행중인 과정({{ ongoingCourses.length }})
                    </li>
                    <li @click="setActiveTab('wait')" :class="{ active: activeTab === 'wait' }">
                        대기중인 과정({{ pendingCourses.length }})
                    </li>
                    <li @click="setActiveTab('finish')" :class="{ active: activeTab === 'finish' }">
                        종료된 과정({{ completedCourses.length }})
                    </li>
                </ul>
            </div>
            <div class="edu_wrap">
                <div class="card-container">
                    <div v-for="(course, index) in displayedCourses" :key="index" class="course-card">
                        <h3>{{ course.course_name }}</h3>
                        <p>수강기간 :{{ course.start_date }} ~ {{ course.end_date }}</p>
                        <p>남은 수강일: {{ course.remainingDays }}일</p>
                        <p>교강사 : {{ course.tutor_name }}</p> <!-- 교강사 이름 표시 -->
                        <ul>
                            <li>현재진행상태 :
                                {{
                                    course.status === 'ing' ? '수강진행' :
                                    course.status === 'wait' ? '수강대기' :
                                        course.status === 'finish' ? '수강죵료' :
                                            course.status
                                }}
                            </li>
                            <li>강의진도 : {{ course.progress }}</li>
                            <li>전체진도율 : {{ course.progress_rate_ing }}%</li>
                        </ul>
                        <button @click="viewDetails(course)">상세보기</button>
                    </div>
                </div>
                <div v-if="totalPages > 1" class="pagination">
                    <button v-for="page in totalPages" :key="page" @click="currentPage = page"
                        :class="{ active: currentPage === page }">
                        {{ page }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import subbar from '../../components/section/subbar.vue';
import axios from 'axios';
import { isLoggedIn } from '@/utils';
export default {
    name: 'MenuwarnPage',
    data() {
        return {
            ongoingCourses: [],
            pendingCourses: [],
            completedCourses: [],
            activeTab: 'ing', // 기본 탭은 진행중인 과정으로 설정
            currentPage: 1,
            itemsPerPage: 6,
            usernum: "",
        };
    },
    beforeRouteEnter(to, from, next) {
        console.log(isLoggedIn());
        if (!isLoggedIn()) {
            next({ path: '/mLogin' });
            alert("로그인해")
        } else {
            next();
        }
    },
    computed: {
        displayedCourses() {
            let courses = [];

            if (this.activeTab === 'ing') {
                courses = this.ongoingCourses;
            } else if (this.activeTab === 'wait') {
                courses = this.pendingCourses;
            } else if (this.activeTab === 'finish') {
                courses = this.completedCourses;
            }
            console.log(courses, "das");
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return courses.slice(start, end);

        },
        totalPages() {
            let coursesLength = 0;
            if (this.activeTab === 'ing') {
                coursesLength = this.ongoingCourses.length;
            } else if (this.activeTab === 'wait') {
                coursesLength = this.pendingCourses.length;
            } else if (this.activeTab === 'finish') {
                coursesLength = this.completedCourses.length;
            }
            return Math.ceil(coursesLength / this.itemsPerPage);
        }
    },
    methods: {
        viewDetails(course) { // 매개변수를 course로 변경하여 course.id와 eduLectureId 모두 접근
            this.$router.push({
                name: 'my_edu_lecture',
                query: {
                    courseId: course.course_id,      // 기존의 course.id
                    eduLectureId: course.eduLectureId // edu_lecture 테이블의 id
                }
            });
        },
        setActiveTab(tab) {
            this.activeTab = tab;
            this.currentPage = 1; // 탭을 변경할 때 페이지를 1로 리셋
        },

        formatDate(dateString) {
            if (!dateString) return ''; // dateString이 없는 경우 빈 문자열 반환

            const date = new Date(dateString);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줌
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        calculateRemainingDays(endDate) {
            const currentDate = new Date(); // 현재 날짜
            const end = new Date(endDate);  // 종료일

            // 두 날짜의 차이를 밀리초로 계산하고, 이를 일수로 변환
            const diffTime = end - currentDate;
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

            return diffDays >= 0 ? diffDays : 0; // 남은 일수가 0보다 작으면 0을 반환
        },
        async fetchTutors(tutorId) {
            try {
                const response = await axios.get(`${API_BASE_URL}/tutor/tutors/${tutorId}`);
                if (response.status === 200) {
                    console.log(response.data.data);
                    return response.data.data; // 교강사 정보를 반환

                } else {
                    console.error('교강사 정보 불러오기 실패:', response.data.message);
                    return null;
                }
            } catch (error) {
                console.error('교강사 목록 조회 에러:', error);
                return null;
            }
        },
        async fetchCourses() {
            try {
                const token = localStorage.getItem('accessToken');
                const userid = localStorage.getItem('id');
                this.usernum = localStorage.getItem('id');

                // Step 1: edu_lecture 테이블에서 course_id와 함께 edu_lecture_id를 가져옴
                const eduLectureResponse = await axios.get(`${API_BASE_URL}/edulecture/user/courses`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        userid: userid
                    }
                });

                if (eduLectureResponse.status === 200) {
                    const eduLectureCourses = eduLectureResponse.data;

                    if (eduLectureCourses.length === 0) {
                        console.log('등록된 과정이 없습니다.');
                        this.ongoingCourses = [];
                        this.pendingCourses = [];
                        this.completedCourses = [];
                        return;
                    }

                    // Step 2: course_id에 해당하는 lecture 데이터를 가져옴
                    const lectureIds = eduLectureCourses.map(course => course.course_id);
                    const lectureResponse = await axios.post(`${API_BASE_URL}/lecture/lecture/courses`, {
                        course_ids: lectureIds
                    }, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                    if (lectureResponse.status === 200) {
                        const lectures = lectureResponse.data;

                        // 교강사 정보를 병합하여 과정 데이터를 구성
                        const mergedCourses = await Promise.all(eduLectureCourses.map(async eduCourse => {
                            const matchingLecture = lectures.find(lecture => lecture.id === eduCourse.course_id);
                            const tutor = await this.fetchTutors(eduCourse.tutor_id);

                            return {
                                ...eduCourse,
                                ...matchingLecture,
                                eduLectureId: eduCourse.id, // edu_lecture 테이블의 id 추가
                                tutor_name: tutor ? tutor.name : 'N/A' // 교강사 이름 추가
                            };
                        }));

                        const formattedCourses = mergedCourses.map(course => ({
                            ...course,
                            start_date: this.formatDate(course.start_date),  // 날짜 형식 변환
                            end_date: this.formatDate(course.end_date),       // 날짜 형식 변환
                            remainingDays: this.calculateRemainingDays(course.end_date)  // 남은 수강일 계산
                        }));

                        this.ongoingCourses = formattedCourses.filter(course => course.status === 'ing');
                        this.pendingCourses = formattedCourses.filter(course => course.status === 'wait');
                        this.completedCourses = formattedCourses.filter(course => course.status === 'finish');
                    } else {
                        alert('과정 데이터를 불러오는 데 실패했습니다.');
                    }
                } else {
                    alert('사용자 등록 과정을 불러오는 데 실패했습니다.');
                }
            } catch (error) {
                console.error('과정 목록 조회 에러:', error);
                alert('과정 목록을 불러오는 중 에러가 발생했습니다.');
            }
        }
    },
    mounted() {
        this.fetchCourses();
    },
    components: {
        subbar,
    },
};
</script>

<style scoped>
@import "../../css/my_edu.css";
@import "../../css/pcsize.css";
</style>
