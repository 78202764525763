<template>
    <div class="px_wrap" @mousemove="notifyParent" @keydown="notifyParent" @click="notifyParent">
        <div class="sb_wrap">

            <ul class="px1320a">
                <li class="sub_1"><img class="mainlogo" @click="homego()" :src="logopath"></li>
                <li class="sub_2">

                    <div class="search_box"><input type="text" name="s_top_keyword" class="search_input"
                            placeholder="검색어를 입력해주세요" value="">
                        <img @click="asd()" class="search_icon" src="../../assets/icon15.png">
                    </div>
                </li>

            </ul>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const IMAGE_BASE_URL = process.env.VUE_APP_IMAGE_BASE_URL;
export default {
    name: 'SubMenu',
    data() {
        return {
            images: [],
            logopath: "",
        };
    },
    mounted() {

        this.fetchFiles();
    },
    methods: {
        async fetchFiles() {
            try {
                const response = await axios.get(`${API_BASE_URL}/logo/files`);
                if (response.data.success) {
                    // 파일 경로를 images 배열에 추가
                    this.images = response.data.data.map(file => ({
                        filepath: `${IMAGE_BASE_URL}/${file.filepath}`
                    }));
                    console.log(this.images[0].filepath, "이미지");
                    this.logopath = this.images[0].filepath

                } else {
                    alert('파일 목록 조회 실패.');
                }
            } catch (error) {
                console.error('파일 목록 조회 에러:', error);
                alert('파일 목록 조회 중 에러가 발생했습니다.');
            }
        },
        homego() {
            this.$router.push({ name: 'MainPage' });
        },
        notifyParent() {

            this.$emit('user-activity');
        }
    },
};
</script>

<style>
@import "../../css/common.css";
@import "../../css/subMenu.css";

.sb_wrap {
    width: 100%;
    height: 125px;
    margin: 0 auto;
    border-bottom: unset !important;
    ;


}
</style>
