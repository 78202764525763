<template>
    <div>
        <div class="board_top_search">
            <ul>
                <li>
                    <select class="form-select search_select" v-model="searchField">
                        <option value="course_name">제목</option>
                    </select>
                </li>
                <li>
                    <input v-model="searchKeyword" class="txtBox search_textbox" type="text">
                </li>
                <li>
                    <button @click="search" class="btn-search search_btn">검색</button>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LectureSearch',
    data() {
        return {
            searchField: 'course_name', // 검색 필드를 'course_name'으로 설정
            searchKeyword: ''
        };
    },
    methods: {
        search() {
            this.$emit('search', {
                field: this.searchField,
                keyword: this.searchKeyword
            });
        }
    }
};
</script>

<style>
@import "../../css/pcsize.css";
</style>
