<template>
    <div class="footer_wrap">
        <div class="inner_wrap">
            <ul class="ft_01">
                <li>교육원소개</li>
                <li>고용환급과정안내</li>
                <li>이용약관</li>
                <li>개인정보보호방침</li>
            </ul>
            <div class="ft_02">
                <p>병근 사이트</p>
                <p> 대표자 : 박병근</p>
                <p>사업자 등록번호 : 000-00-00000</p><br>
                <p>부산시 ○○○ </p>
                <p> 대표전화 : </p>
                <p> 팩스 : </p>
                <br>
                <p>이메일 : qudrms1648@gmail.com </p>
                <p> 개인정보보호 관리자 : 박병근</p>

                <br><br>
                <p class="copy">COPYRIGHT ⓒ 2024 병근 사이트. ALL RIGHT PRESERVED.</p>
            </div>

        </div>
    </div>
</template>

<script>
//import axios from 'axios';
//const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
//const IMAGE_BASE_URL = process.env.VUE_APP_IMAGE_BASE_URL;
export default {
    name: 'pageFter',
    data() {
        return {

        };
    },
    computed: {

    },
    mounted() {



    },
    methods: {

    },
};
</script>

<style>
@import "../../css/footer.css";
</style>
