<template>
    <div>
        <h2>FAQ 작성</h2>
        <label for="title">글 제목:</label>
        <input type="text" v-model="title" id="title" required />

        <label for="content">글 내용:</label>
        <ckeditor :editor="editor" v-model="content" :config="editorConfig"></ckeditor>

        <button @click="submitFaq">저장</button>
    </div>
</template>

<script>
import axios from 'axios';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'FaqInsert',
    components: {
        ckeditor: Ckeditor, // CKEditor 대신 Ckeditor로 변경
    },
    data() {
        return {
            title: '',
            content: '',
            editor: ClassicEditor,
            editorInstance: null,
            API_BASE_URL: process.env.VUE_APP_API_BASE_URL, // 환경 변수에서 API_BASE_URL 가져오기
        };
    },
    computed: {
        editorConfig() {
            return {
                toolbar: [
                    'heading', '|', 'bold', 'italic', 'link',
                    'bulletedList', 'numberedList', 'blockQuote', 'imageUpload',
                ],
                ckfinder: {
                    uploadUrl: `${this.API_BASE_URL}/ckeditor/upload`,
                },
                removePlugins: ['Autosave'], // 문제가 될 수 있는 플러그인 제거
            };
        },
    },
    mounted() {
        this.initializeEditor();
    },
    beforeUnmount() {
        this.destroyEditor();
    },
    methods: {
        initializeEditor() {
            this.editor.create(document.querySelector('#editor'))
                .then(editor => {
                    this.editorInstance = editor;
                })
                .catch(error => {
                    console.error('CKEditor initialization failed:', error);
                });
        },
        destroyEditor() {
            if (this.editorInstance) {
                this.editorInstance.destroy()
                    .then(() => {
                        console.log('CKEditor instance destroyed');
                        this.initializeEditor(); // 다시 초기화
                    })
                    .catch(error => {
                        console.error('CKEditor destroy failed:', error);
                    });
            }
        },
        async submitFaq() {
            try {
                if (!this.title || !this.content.trim()) {
                    alert('제목과 내용을 모두 입력해 주세요.');
                    return;
                }

                const response = await axios.post(`${this.API_BASE_URL}/faq/add`, {
                    title: this.title,
                    content: this.content,
                });

                if (response.data && response.data.message) {
                    alert('FAQ가 성공적으로 저장되었습니다.');
                    this.title = '';
                    this.content = '';
                    this.destroyEditor(); // 성공적으로 저장되었을 때 에디터를 초기화
                } else {
                    alert('FAQ 저장에 실패했습니다.');
                }
            } catch (error) {
                console.error('Error saving FAQ:', error);
                alert('FAQ 저장 중 오류가 발생했습니다.');
            }
        },
    },
};
</script>

<style scoped>
.mobileshow {
    display: none;
}
</style>
