<template>
    <div>
        <subbar_lecture />
        <div class="sub_menu">
            <ul>
                <li v-for="item in categories" :key="item.id">
                    <a :href="item.link">{{ item.title
                    }}</a>
                </li>
            </ul>
        </div>
        <lecturesearch @search="handleSearch" />

        <div class="lecture_list_wrap">
            <div class="lecture_card" v-for="(item, index) in paginatedItems" :key="index">
                <img :src="item.image_url" alt="Lecture Image" class="lecture_image" />
                <div class="card_content">
                    <h3>{{ item.course_name }}</h3>
                    <p>{{ item.description }}</p>
                    <button @click="viewDetails(item.id)">과정보기</button>
                </div>
            </div>
        </div>

        <div class="pagination">
            <button class="backbtn" @click="changePage(currentPage - 1)" :disabled="currentPage === 1">이전</button>
            <button v-for="page in totalPages" :key="page" @click="changePage(page)"
                :class="{ active: currentPage === page }">
                {{ page }}
            </button>
            <button class="nextbtn" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">다음</button>
        </div>
    </div>
</template>

<script>
import subbar_lecture from '../../components/section/subbar_lecture.vue';
import lecturesearch from '../../components/section/lecturesearch.vue';
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'LectureList',
    data() {
        return {
            items: [], // 강의 데이터를 저장할 배열
            currentPage: 1, // 현재 페이지 번호
            pageSize: 6, // 페이지당 강의 수
            searchField: 'title', // 검색 필드
            searchKeyword: '', // 검색 키워드
            parentid: "", // 부모 ID 저장
            categories: [],
            categoryCode: null, // 선택된 카테고리 코드
        };
    },
    components: {
        subbar_lecture,
        lecturesearch
    },
    computed: {
        paginatedItems() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.items.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.items.length / this.pageSize);
        }
    },
    methods: {
        async fetchCategories() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                this.parentid = urlParams.get('parent');
                this.categoryCode = urlParams.get('category_code');

                const response = await axios.get(`${API_BASE_URL}/navigation/sub2-navigation`, {
                    params: { parentId: this.parentid }
                });

                if (response.data.success) {
                    this.categories = response.data.data; // categories에 카테고리 데이터를 저장
                    console.log(this.categories, "cate");

                    // 카테고리 데이터를 불러온 후에 updateItemWidths를 호출
                    this.updateItemWidths();
                } else {
                    alert('카테고리 조회 실패');
                }
            } catch (error) {
                console.error('카테고리 조회 중 에러 발생:', error);
                alert('카테고리 조회 중 에러가 발생했습니다.');
            }
        },
        async fetchData() {
            try {
                const params = {
                    search_field: this.searchField,
                    search_keyword: this.searchKeyword,
                    parent_code: this.parentid, // 상위 카테고리 필터 추가
                    category_code: this.categoryCode // 카테고리 필터 추가
                };
                const response = await axios.get(`${API_BASE_URL}/lecture`, { params }); // API 호출
                if (response.data.success) {
                    this.items = response.data.data; // API로부터 데이터를 items 배열에 저장
                } else {
                    alert('데이터를 가져오는 데 실패했습니다.');
                }
            } catch (error) {
                console.error('데이터 가져오기 중 오류 발생:', error);
            }
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        viewDetails(id) {
            this.$router.push({ path: `/lecture/detail/${id}` }); // 강의 세부 정보로 이동
        },
        handleSearch(searchParams) {
            this.searchField = searchParams.field;
            this.searchKeyword = searchParams.keyword;
            this.fetchData(); // 검색 조건에 따라 데이터를 다시 가져옵니다.
        },
        updateItemWidths() {
            const navItemsCount = this.categories.length;
            console.log(navItemsCount, "nav");
            const widthPercentage = 100 / navItemsCount - 0.12;
            console.log(widthPercentage);

            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `
                .sub_menu ul li {
                    width: ${widthPercentage}%;
                }
            `;
            document.head.appendChild(style);
        },
        updatebtncolor() {
            const urlParams = new URLSearchParams(window.location.search);
            this.categoryCode = urlParams.get('category_code');
            console.log(this.categoryCode);
            const style = document.createElement('style');
            style.type = 'text/css';
            if (this.categoryCode === null) {
                style.innerHTML = `
                    .sub_menu ul li:nth-child(1) {
                        background-color: #ff6600;
                        color: white !important;
                    }
                    .sub_menu ul li:nth-child(1) > a {
                        color: white !important;
                    }
                `;
            } else {
                style.innerHTML = `
                    .sub_menu ul li:nth-child(${this.categoryCode}) {
                        background-color: #ff6600;
                        color: white !important;
                    }
                    .sub_menu ul li:nth-child(${this.categoryCode}) > a {
                        color: white !important;
                    }
                `;
            }
            document.head.appendChild(style);
        }
    },
    mounted() {
        this.fetchCategories(); // 카테고리 데이터를 먼저 가져옴
        this.fetchData(); // 컴포넌트가 마운트될 때 데이터 가져오기
        this.updatebtncolor(); // 카테고리 버튼 색상 업데이트
    }
};
</script>

<style scoped>
@import "../../css/lecturelist.css";
@import "../../css/pcsize.css";
</style>
