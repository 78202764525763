<template>
    <div style="width:70%; margin: 0 auto;">
        <h2>{{ lecture.course_name }} - 차시 목록</h2>
        <div v-for="(content, index) in contents" :key="index" class="lecture-item">
            <div class="lecture-header">
                <span>[{{ index + 1 }}차시] &nbsp;</span>
                <span>{{ content.content_name }}</span>
            </div>
            <div class="lecture-footer">
                <button @click="openModal(content)" class="study-btn" :disabled="!isEligibleToStudy(index)">
                    {{ content.completed ? '복습하기' : '수강하기' }}
                </button>
                <span v-if="content.completed" class="completed-label">수강완료</span>
            </div>
        </div>
        <!-- 중간평가 버튼 -->
        <button v-if="hasMidtermQuestions" @click="startEvaluation('midterm')" class="eval-btn"
            :disabled="!allContentsCompleted || isMidtermCompleted">
            {{ isMidtermCompleted ? '중간평가 완료' : '중간평가 응시하기' }}
        </button>

        <!-- 최종평가 버튼 -->
        <button v-if="hasFinalQuestions" @click="startEvaluation('final')" class="eval-btn"
            :disabled="!isMidtermCompleted || isFinalCompleted">
            {{ isFinalCompleted ? '최종평가 완료' : '최종평가 응시하기' }}
        </button>

        <!-- 과제평가 버튼 -->
        <button v-if="hasTaskQuestions" @click="startEvaluation('task')" class="eval-btn"
            :disabled="!isFinalCompleted || isTaskCompleted">
            {{ isTaskCompleted ? '과제평가 완료' : '과제평가 응시하기' }}
        </button>

        <!-- 수강 모달 -->
        <div v-if="isModalOpen" class="modal_overlay" @click="closeModal">
            <div class="modal_content" @click.stop>
                <h3>{{ currentContent.content_name }}</h3>
                <iframe :src="currentContent.content_link" width="100%" height="400px"></iframe>
                <button @click="completeContent(currentContent)">수강 완료</button>
                <button @click="closeModal">닫기</button>
            </div>
        </div>

        <!-- 평가 모달 -->
        <div v-if="isEvaluationModalOpen" class="modal_overlay" @click="closeEvaluationModal">
            <div class="modal_content" @click.stop>
                <h3>{{ evaluationTypeName }} 응시</h3>
                <form @submit.prevent="submitEvaluation">
                    <div v-for="(question, index) in evaluationQuestions" :key="index" class="question-block">
                        <label :for="'question-' + index">{{ index + 1 }}. {{ question.question }}</label>

                        <!-- 객관식 (Multiple Choice) -->
                        <div v-if="question.question_type === 'multiple_choice'">
                            <div v-for="(option, optionIndex) in question.options" :key="optionIndex">
                                <input type="radio" :id="'question-' + index + '-option-' + optionIndex"
                                    :name="'question-' + index" :value="option" v-model="evaluationAnswers[index]" />
                                <label :for="'question-' + index + '-option-' + optionIndex">{{ option }}</label>
                            </div>
                        </div>

                        <!-- 단답형 (Short Answer) -->
                        <div v-if="question.question_type === 'short_answer'">
                            <input type="text" :id="'question-' + index" v-model="evaluationAnswers[index]"
                                placeholder="정답을 입력하세요." />
                        </div>

                        <div v-if="question.question_type === 'essay'">
                            <input type="text" :id="'question-' + index" v-model="evaluationAnswers[index]"
                                placeholder="정답을 입력하세요." />
                        </div>

                        <!-- 진위형 (True/False) -->
                        <div v-if="question.question_type === 'true_false'">
                            <input type="radio" :id="'question-' + index + '-true'" :name="'question-' + index" value="O"
                                v-model="evaluationAnswers[index]" />
                            <label :for="'question-' + index + '-true'">O</label>

                            <input type="radio" :id="'question-' + index + '-false'" :name="'question-' + index" value="X"
                                v-model="evaluationAnswers[index]" />
                            <label :for="'question-' + index + '-false'">X</label>
                        </div>
                    </div>
                    <button type="submit">제출</button>
                </form>
                <button @click="closeEvaluationModal">닫기</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    data() {
        return {
            eduLectureId: this.$route.query.eduLectureId, // edu_lecture의 ID
            courseId: this.$route.query.courseId, // courseId
            lecture: {},
            contents: [],
            isModalOpen: false,
            currentContent: null,
            isMidtermCompleted: false,
            isFinalCompleted: false,
            isTaskCompleted: false,
            isEvaluationModalOpen: false,
            evaluationType: '',
            evaluationTypeName: '',
            evaluationQuestions: [],
            evaluationAnswers: [],
            hasMidtermQuestions: false,  // 중간평가 질문 존재 여부
            hasFinalQuestions: false,    // 최종평가 질문 존재 여부
            hasTaskQuestions: false      // 과제평가 질문 존재 여부
        };
    },
    computed: {
        allContentsCompleted() {
            return this.contents.every(content => content.completed);
        }
    },
    methods: {
        // 진도율 계산 및 업데이트 함수
        // 진도율 및 강의 진도 계산 및 업데이트 함수
        async calculateAndUpdateProgress() {
            try {
                const completedCount = this.contents.filter(content => content.completed).length;
                console.log(completedCount);
                const totalCount = this.contents.length;
                console.log(totalCount);
                const progressRate = (completedCount / totalCount) * 100;

                // 진도율 및 강의 진도를 백엔드에 저장
                const response = await axios.post(`${API_BASE_URL}/lecture/update_progress`, {
                    edu_lecture_id: this.eduLectureId,
                    progress_rate_ing: progressRate.toFixed(2),  // 백분율로 계산 후 소수점 두 자리까지 저장
                    completed_count: completedCount,          // 완료한 차시 수
                    total_count: totalCount                   // 전체 차시 수
                });

                console.log(response);

                if (!response.data.success) {
                    console.log("das");
                    console.error('진도율 및 강의 진도 저장 중 오류 발생:', response.data.message);
                }
            } catch (error) {
                console.log("ㅌㅌ")
                console.error('진도율 및 강의 진도 계산 및 저장 중 오류 발생:', error);
            }
        },

        async fetchEvaluationStatus() {
            try {
                const response = await axios.get(`${API_BASE_URL}/evaluation/status`, {
                    params: {
                        edu_lecture_id: this.eduLectureId,
                    }
                });

                if (response.data.success) {
                    const evaluations = response.data.data;
                    // 각 평가 타입의 완료 여부를 설정
                    this.isMidtermCompleted = evaluations.some(evaluation => evaluation.evaluation_type === 'midterm');
                    this.isFinalCompleted = evaluations.some(evaluation => evaluation.evaluation_type === 'final');
                    this.isTaskCompleted = evaluations.some(evaluation => evaluation.evaluation_type === 'task');

                    // 평가가 없는 경우 수강 상태를 "종료된 과정"으로 변경
                    console.log("asd!@");
                    if (evaluations.length === 0 && this.contents.every(content => content.completed)) {
                        await axios.post(`${API_BASE_URL}/lecture/complete_course`, {
                            edu_lecture_id: this.eduLectureId
                        });
                        this.status = 'finish';
                    }
                }
            } catch (error) {
                console.error('평가 상태를 가져오는 중 오류 발생:', error);
            }
        },
        parseOptions(options) {
            try {
                return JSON.parse(options);
            } catch (error) {
                console.error('옵션 파싱 중 오류 발생:', error);
                return []; // 오류 발생 시 빈 배열 반환
            }
        },
        startEvaluation(type) {
            this.openEvaluationModal(type);
        },

        async updateLectureStatus() {
            try {
                const response = await axios.post(`${API_BASE_URL}/lecture/update_status`, {
                    edu_lecture_id: this.eduLectureId,
                    status: 'finish'
                });

                if (!response.data.success) {
                    console.error('수강 상태 업데이트 중 오류 발생:', response.data.message);
                }
            } catch (error) {
                console.error('수강 상태 업데이트 중 오류 발생:', error);
            }
        },

        async fetchEvaluationQuestions(type) {
            try {
                const response = await axios.get(`${API_BASE_URL}/evaluation/evaluation_questions`, {
                    params: { lecture_id: this.courseId, evaluation_type: type }
                });

                if (response.data.success && response.data.questions.length > 0) {
                    this.evaluationQuestions = response.data.questions.map(question => ({
                        ...question,
                        options: this.parseOptions(question.options)
                    }));
                    this.evaluationAnswers = new Array(this.evaluationQuestions.length).fill('');

                    // 평가 유형에 따라 상태 업데이트
                    if (type === 'midterm') this.hasMidtermQuestions = true;
                    if (type === 'final') this.hasFinalQuestions = true;
                    if (type === 'task') this.hasTaskQuestions = true;

                } else {

                    this.evaluationQuestions = [];
                    if (type === 'midterm') this.hasMidtermQuestions = false;
                    if (type === 'final') this.hasFinalQuestions = false;
                    if (type === 'task') this.hasTaskQuestions = false;
                }
            } catch (error) {
                console.error('평가 질문을 가져오는 중 오류 발생:', error);
            }
        },

        async submitEvaluation() {
            try {
                // answers 배열을 구성하여 질문 ID와 사용자의 답안을 포함
                const answers = this.evaluationQuestions.map((question, index) => ({
                    question_id: question.id,
                    user_answer: this.evaluationAnswers[index],
                    correct_answer: question.correct_answer  // 백엔드에서 사용자가 정답과 비교할 수 있도록 합니다
                }));

                const response = await axios.post(`${API_BASE_URL}/evaluation/submit_evaluation`, {
                    edu_lecture_id: this.eduLectureId,
                    lecture_id: this.courseId,
                    user_id: localStorage.getItem('id'),
                    evaluation_type: this.evaluationType,
                    answers: answers
                });

                if (response.data.success) {
                    alert(`평가가 성공적으로 제출되었습니다. 점수: ${response.data.score}`);
                    this.closeEvaluationModal();


                    if (this.evaluationType === 'midterm') {
                        this.isMidtermCompleted = true;
                    } else if (this.evaluationType === 'final') {
                        this.isFinalCompleted = true;
                    } else if (this.evaluationType === 'task') {
                        this.isTaskCompleted = true;
                    }
                    // 평가가 없는 경우와 마지막 평가 완료 시 수강 상태를 종료로 변경
                    await this.checkAndUpdateLectureStatus();
                } else {
                    alert('평가 제출 중 오류가 발생했습니다.');
                }
            } catch (error) {
                console.error('평가 제출 중 오류 발생:', error);
                alert('평가 제출 중 오류가 발생했습니다.');
            }
        },
        async checkAndUpdateLectureStatus() {
            try {
                const response = await axios.get(`${API_BASE_URL}/evaluation/status`, {
                    params: { edu_lecture_id: this.eduLectureId }
                });

                if (response.data.success) {
                    const evaluations = response.data.data;

                    const isMidtermCompleted = evaluations.some(evaluation => evaluation.evaluation_type === 'midterm');
                    const isFinalCompleted = evaluations.some(evaluation => evaluation.evaluation_type === 'final');
                    const isTaskCompleted = evaluations.some(evaluation => evaluation.evaluation_type === 'task');

                    const hasEvaluations = this.hasMidtermQuestions || this.hasFinalQuestions || this.hasTaskQuestions;

                    // 평가가 없는 경우: 진도율이 100%인지 확인 후 종료
                    if (!hasEvaluations) {
                        if (this.contents.every(content => content.completed)) {
                            await this.updateLectureStatus();
                        }
                    } else if (
                        (this.hasMidtermQuestions && isMidtermCompleted && !this.hasFinalQuestions && !this.hasTaskQuestions) ||
                        (this.hasFinalQuestions && isFinalCompleted && !this.hasMidtermQuestions && !this.hasTaskQuestions) ||
                        (this.hasTaskQuestions && isTaskCompleted && !this.hasMidtermQuestions && !this.hasFinalQuestions) ||
                        (this.hasMidtermQuestions && isMidtermCompleted && this.hasFinalQuestions && isFinalCompleted && !this.hasTaskQuestions) ||
                        (this.hasFinalQuestions && isFinalCompleted && this.hasTaskQuestions && isTaskCompleted && !this.hasMidtermQuestions) ||
                        (this.hasMidtermQuestions && isMidtermCompleted && this.hasTaskQuestions && isTaskCompleted && !this.hasFinalQuestions) ||
                        (this.hasMidtermQuestions && isMidtermCompleted && this.hasFinalQuestions && isFinalCompleted && this.hasTaskQuestions && isTaskCompleted)
                    ) {
                        // 마지막 평가가 완료된 경우 수강 상태를 종료로 변경
                        await this.updateLectureStatus();
                    }
                }
            } catch (error) {
                console.error('수강 상태 확인 중 오류 발생:', error);
            }
        },

        async fetchLecture() {
            try {
                const response = await axios.get(`${API_BASE_URL}/lecture/lecture/${this.courseId}`);
                if (response.data.success) {
                    this.lecture = response.data.data;
                    console.log(this.lecture);
                } else {
                    console.error('강의 정보를 불러오는 중 오류 발생:', response.data.message);
                }
            } catch (error) {
                console.error('강의 정보를 불러오는 중 오류 발생:', error);
            }
        },
        async fetchContents() {
            try {
                const response = await axios.get(`${API_BASE_URL}/lecture/lecture_contents_with_completion`, {
                    params: {
                        edu_lecture_id: this.eduLectureId,
                        user_id: localStorage.getItem('id')
                    }
                });
                this.contents = response.data.data;
                console.log(this.contents);
            } catch (error) {
                console.error('차시 목록을 가져오는 중 오류 발생:', error);
            }
        },
        isEligibleToStudy(index) {
            return index === 0 || this.contents[index - 1].completed;
        },
        openModal(content) {
            this.currentContent = content;
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },

        // 차시 완료 후 진도율 및 강의 진도 업데이트
        async completeContent(content) {
            try {
                await axios.post(`${API_BASE_URL}/lecture/lecture_contents/complete`, {
                    edu_lecture_id: this.eduLectureId,
                    content_id: content.id,
                    user_id: localStorage.getItem('id'),
                    is_review: content.completed // 이미 완료된 상태라면 복습임을 알리는 플래그
                });

                if (content.completed) {
                    content.review_completed_at = new Date(); // 복습 시간 갱신
                } else {
                    content.completed = true; // 처음 완료
                    content.completed_at = new Date(); // 완료 시간 저장
                }
                // 완료 후 진도율 계산 및 업데이트
                await this.calculateAndUpdateProgress();

                this.closeModal();
            } catch (error) {
                console.error('차시 완료 처리 중 오류 발생:', error);
            }
        },
        openEvaluationModal(type) {
            this.evaluationType = type;
            this.evaluationTypeName = this.getEvaluationTypeName(type);
            this.fetchEvaluationQuestions(type);
            this.isEvaluationModalOpen = true;
        },
        getEvaluationTypeName(type) {
            switch (type) {
                case 'midterm':
                    return '중간평가';
                case 'final':
                    return '최종평가';
                case 'task':
                    return '과제평가';
                default:
                    return '';
            }
        },
        closeEvaluationModal() {
            this.isEvaluationModalOpen = false;
        }
    },
    mounted() {
        this.fetchLecture(); // 강의 상세 정보 가져오기 (courseId를 사용)
        this.fetchContents(); // 차시 목록 가져오기 (eduLectureId와 연동)
        this.fetchEvaluationStatus(); // 중간평가 상태 가져오기
        // 평가 질문 상태를 불러옴
        this.fetchEvaluationQuestions('midterm');
        this.fetchEvaluationQuestions('final');
        this.fetchEvaluationQuestions('task');

    }
};
</script>

<style scoped>
.lecture-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
}

.lecture-header {
    font-weight: bold;
}

.lecture-footer {
    margin-top: 10px;
}

.study-btn {
    padding: 5px 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.study-btn:disabled {
    background-color: #ccc;
}

.eval-btn {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.eval-btn:disabled {
    background-color: #ccc;
}

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal_content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: 80%;
    overflow: auto;
    z-index: 99999;
}

button {
    margin-top: 20px;
}

.question-block {
    margin-bottom: 20px;
}
</style>