<template>
    <div class="wrap">
        <p>상위 네비게이션 설정</p>
        <input v-model="newTitle" placeholder="제목">
        <input v-model="newLink" placeholder="링크">
        <button @click="addNavigationItem">추가</button>

        <div>
            <table border="1" cellspacing="0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>제목</th>
                        <th>링크</th>
                        <th>수정</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in navigationItems" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>
                            <template v-if="editModeNav && editIdNav === item.id">
                                <input v-model="editTitleNav">
                            </template>
                            <template v-else>
                                {{ item.title }}
                            </template>
                        </td>
                        <td>
                            <template v-if="editModeNav && editIdNav === item.id">
                                <input v-model="editLinkNav">
                            </template>
                            <template v-else>
                                {{ item.link }}
                            </template>
                        </td>
                        <td>
                            <template v-if="editModeNav && editIdNav === item.id">
                                <button @click="updateNavigationItem(item.id)">저장</button>
                                <button @click="cancelEditNav">취소</button>
                            </template>
                            <template v-else>
                                <button @click="editNavigationItem(item)">수정</button>
                            </template>
                        </td>
                        <td><button @click="deleteNavigationItem(item.id)">삭제</button></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p>하위 네비게이션 설정</p>
        <input v-model="newSubTitle" placeholder="제목">
        <input v-model="newSubLink" placeholder="링크">
        <select v-model="newParentId">
            <option :value="null">상위 선택</option>
            <option v-for="item in navigationItems" :key="item.id" :value="item.id">{{ item.title }}</option>
        </select>
        <button @click="addSubNavigationItem">추가</button>

        <div>
            <table border="1" cellspacing="0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>제목</th>
                        <th>링크</th>
                        <th>상위 항목</th>
                        <th>수정</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in subNavigationItems" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>
                            <template v-if="editModeSub && editIdSub === item.id">
                                <input v-model="editTitleSub">
                            </template>
                            <template v-else>
                                {{ item.title }}
                            </template>
                        </td>
                        <td>
                            <template v-if="editModeSub && editIdSub === item.id">
                                <input v-model="editLinkSub">
                            </template>
                            <template v-else>
                                {{ item.link }}
                            </template>
                        </td>
                        <td>{{ getParentTitle(item.parent_id) }}</td>
                        <td>
                            <template v-if="editModeSub && editIdSub === item.id">
                                <button @click="updateSubNavigationItem(item.id)">저장</button>
                                <button @click="cancelEditSub">취소</button>
                            </template>
                            <template v-else>
                                <button @click="editSubNavigationItem(item)">수정</button>
                            </template>
                        </td>
                        <td><button @click="deleteSubNavigationItem(item.id)">삭제</button></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <p>하위2 네비게이션 설정</p>
        <input v-model="newSub2Title" placeholder="제목">
        <input v-model="newSub2Link" placeholder="링크">
        <select v-model="newSubParentId">
            <option :value="null">상위 선택</option>
            <option v-for="item in subNavigationItems" :key="item.id" :value="item.id">{{ item.title }}</option>
        </select>
        <button @click="addSub2NavigationItem">추가</button>

        <div>
            <table border="1" cellspacing="0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>제목</th>
                        <th>링크</th>
                        <th>상위 항목</th>
                        <th>순번</th>
                        <th>수정</th>
                        <th>삭제</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in sub2NavigationItems" :key="item.id">
                        <td>{{ item.id }}</td>
                        <td>
                            <template v-if="editModeSub2 && editIdSub2 === item.id">
                                <input v-model="editTitleSub2">
                            </template>
                            <template v-else>
                                {{ item.title }}
                            </template>
                        </td>
                        <td>
                            <template v-if="editModeSub2 && editIdSub2 === item.id">
                                <input v-model="editLinkSub2">
                            </template>
                            <template v-else>
                                {{ item.link }}
                            </template>
                        </td>
                        <td>{{ getSubParentTitle(item.parent_id) }}({{ item.parent_id }})</td>
                        <td>
                            <template v-if="editModeSub2 && editIdSub2 === item.id">
                                <input v-model="editNumSub2">
                            </template>
                            <template v-else>
                                {{ item.num }}
                            </template>
                        </td>
                        <td>
                            <template v-if="editModeSub2 && editIdSub2 === item.id">
                                <button @click="updateSub2NavigationItem(item.id)">저장</button>
                                <button @click="cancelEditSub2">취소</button>
                            </template>
                            <template v-else>
                                <button @click="editSub2NavigationItem(item)">수정</button>
                            </template>
                        </td>
                        <td><button @click="deleteSub2NavigationItem(item.id)">삭제</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'AdminPage2',
    data() {
        return {
            newTitle: '',
            newLink: '',
            newSubTitle: '',
            newSubLink: '',
            newSub2Title: '',
            newSub2Link: '',
            newParentId: null,
            newSubParentId: null,
            navigationItems: [],
            subNavigationItems: [],
            sub2NavigationItems: [],
            editModeNav: false,
            editIdNav: null,
            editTitleNav: '',
            editLinkNav: '',
            editModeSub: false,
            editIdSub: null,
            editTitleSub: '',
            editLinkSub: '',
            editModeSub2: false,
            editIdSub2: null,
            editTitleSub2: '',
            editLinkSub2: '',
            editNumSub2: '',
        };
    },
    methods: {
        async fetchNavigationItems() {
            try {
                const response = await axios.get(`${API_BASE_URL}/navigation`);
                if (response.data.success) {
                    this.navigationItems = response.data.data;
                } else {
                    alert('네비게이션 항목 조회 실패.');
                }
            } catch (error) {
                console.error('네비게이션 항목 조회 에러:', error);
                alert('네비게이션 항목 조회 중 에러가 발생했습니다.');
            }
        },
        async fetchSubNavigationItems() {
            try {
                const response = await axios.get(`${API_BASE_URL}/navigation/sub-navigation`);
                if (response.data.success) {
                    this.subNavigationItems = response.data.data;
                } else {
                    alert('하위 네비게이션 항목 조회 실패.');
                }
            } catch (error) {
                console.error('하위 네비게이션 항목 조회 에러:', error);
                alert('하위 네비게이션 항목 조회 중 에러가 발생했습니다.');
            }
        },
        async fetchSub2NavigationItems() {
            try {
                const response = await axios.get(`${API_BASE_URL}/navigation/sub2-navigation`);
                if (response.data.success) {
                    this.sub2NavigationItems = response.data.data;
                } else {
                    alert('하위2 네비게이션 항목 조회 실패.');
                }
            } catch (error) {
                console.error('하위2 네비게이션 항목 조회 에러:', error);
                alert('하위2 네비게이션 항목 조회 중 에러가 발생했습니다.');
            }
        },
        async addNavigationItem() {
            if (!this.newTitle) {
                alert('제목을 입력해주세요.');
                return;
            }

            try {
                const response = await axios.post(`${API_BASE_URL}/navigation`, {
                    title: this.newTitle,
                    link: this.newLink
                });
                if (response.data.success) {
                    alert('네비게이션 항목 추가 성공!');
                    this.newTitle = '';
                    this.newLink = '';
                    this.fetchNavigationItems(); // 항목 추가 후 목록 갱신
                } else {
                    alert('네비게이션 항목 추가 실패.');
                }
            } catch (error) {
                console.error('네비게이션 항목 추가 에러:', error);
                alert('네비게이션 항목 추가 중 에러가 발생했습니다.');
            }
        },
        async addSubNavigationItem() {
            if (!this.newSubTitle || !this.newParentId) {
                alert('제목과 상위 항목을 선택해주세요.');
                return;
            }

            try {
                const response = await axios.post(`${API_BASE_URL}/navigation/sub-navigation`, {
                    parentId: this.newParentId,
                    title: this.newSubTitle,
                    link: this.newSubLink
                });
                if (response.data.success) {
                    alert('하위 네비게이션 항목 추가 성공!');
                    this.newSubTitle = '';
                    this.newSubLink = '';
                    this.newParentId = null;
                    this.fetchSubNavigationItems(); // 항목 추가 후 목록 갱신
                } else {
                    alert('하위 네비게이션 항목 추가 실패.');
                }
            } catch (error) {
                console.error('하위 네비게이션 항목 추가 에러:', error);
                alert('하위 네비게이션 항목 추가 중 에러가 발생했습니다.');
            }
        },
        async addSub2NavigationItem() {
            if (!this.newSub2Title || !this.newSubParentId) {
                alert('제목과 상위 항목을 선택해주세요.');
                return;
            }

            try {
                const response = await axios.post(`${API_BASE_URL}/navigation/sub2-navigation`, {
                    parentId: this.newSubParentId,
                    title: this.newSub2Title,
                    link: this.newSub2Link
                });
                if (response.data.success) {
                    alert('하위2 네비게이션 항목 추가 성공!');
                    this.newSub2Title = '';
                    this.newSub2Link = '';
                    this.newSubParentId = null;
                    this.fetchSub2NavigationItems(); // 항목 추가 후 목록 갱신
                } else {
                    alert('하위2 네비게이션 항목 추가 실패.');
                }
            } catch (error) {
                console.error('하위2 네비게이션 항목 추가 에러:', error);
                alert('하위2 네비게이션 항목 추가 중 에러가 발생했습니다.');
            }
        },
        async deleteNavigationItem(id) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/navigation/${id}`);
                if (response.data.success) {
                    alert('네비게이션 항목 삭제 성공!');
                    this.fetchNavigationItems(); // 항목 삭제 후 목록 갱신
                } else {
                    alert('네비게이션 항목 삭제 실패.');
                }
            } catch (error) {
                console.error('네비게이션 항목 삭제 에러:', error);
                alert('네비게이션 항목 삭제 중 에러가 발생했습니다.');
            }
        },
        async deleteSubNavigationItem(id) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/navigation/sub-navigation/${id}`);
                if (response.data.success) {
                    alert('하위 네비게이션 항목 삭제 성공!');
                    this.fetchSubNavigationItems(); // 항목 삭제 후 목록 갱신
                } else {
                    alert('하위 네비게이션 항목 삭제 실패.');
                }
            } catch (error) {
                console.error('하위 네비게이션 항목 삭제 에러:', error);
                alert('하위 네비게이션 항목 삭제 중 에러가 발생했습니다.');
            }
        },
        async deleteSub2NavigationItem(id) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/navigation/sub2-navigation/${id}`);
                if (response.data.success) {
                    alert('하위2 네비게이션 항목 삭제 성공!');
                    this.fetchSub2NavigationItems(); // 항목 삭제 후 목록 갱신
                } else {
                    alert('하위2 네비게이션 항목 삭제 실패.');
                }
            } catch (error) {
                console.error('하위2 네비게이션 항목 삭제 에러:', error);
                alert('하위2 네비게이션 항목 삭제 중 에러가 발생했습니다.');
            }
        },
        editNavigationItem(item) {

            this.editModeNav = true;
            this.editIdNav = item.id;
            this.editTitleNav = item.title;
            this.editLinkNav = item.link;
        },
        async updateNavigationItem(id) {
            try {
                const response = await axios.put(`${API_BASE_URL}/navigation/${id}`, {
                    title: this.editTitleNav,
                    link: this.editLinkNav
                });
                if (response.data.success) {
                    alert('네비게이션 항목 수정 성공!');
                    this.editModeNav = false;
                    this.editIdNav = null;
                    this.editTitleNav = '';
                    this.editLinkNav = '';
                    this.fetchNavigationItems(); // 항목 수정 후 목록 갱신
                } else {
                    alert('네비게이션 항목 수정 실패.');
                }
            } catch (error) {
                console.error('네비게이션 항목 수정 에러:', error);
                alert('네비게이션 항목 수정 중 에러가 발생했습니다.');
            }
        },
        editSubNavigationItem(item) {
            this.editModeSub = true;
            this.editIdSub = item.id;
            this.editTitleSub = item.title;
            this.editLinkSub = item.link;
        },
        async updateSubNavigationItem(id) {
            try {
                const response = await axios.put(`${API_BASE_URL}/navigation/sub-navigation/${id}`, {
                    title: this.editTitleSub,
                    link: this.editLinkSub
                });
                if (response.data.success) {
                    alert('하위 네비게이션 항목 수정 성공!');
                    this.editModeSub = false;
                    this.editIdSub = null;
                    this.editTitleSub = '';
                    this.editLinkSub = '';
                    this.fetchSubNavigationItems(); // 항목 수정 후 목록 갱신
                } else {
                    alert('하위 네비게이션 항목 수정 실패.');
                }
            } catch (error) {
                console.error('하위 네비게이션 항목 수정 에러:', error);
                alert('하위 네비게이션 항목 수정 중 에러가 발생했습니다.');
            }
        },
        editSub2NavigationItem(item) {
            console.log(item);
            this.editModeSub2 = true;
            this.editIdSub2 = item.id;
            this.editTitleSub2 = item.title;
            this.editLinkSub2 = item.link;
            this.editNumSub2 = item.num;
        },
        async updateSub2NavigationItem(id) {
            try {
                const response = await axios.put(`${API_BASE_URL}/navigation/sub2-navigation/${id}`, {
                    title: this.editTitleSub2,
                    link: this.editLinkSub2,
                    num: this.editNumSub2
                });
                if (response.data.success) {
                    alert('하위2 네비게이션 항목 수정 성공!');
                    this.editModeSub2 = false;
                    this.editIdSub2 = null;
                    this.editTitleSub2 = '';
                    this.editLinkSub2 = '';
                    this.fetchSub2NavigationItems(); // 항목 수정 후 목록 갱신
                } else {
                    alert('하위2 네비게이션 항목 수정 실패.');
                }
            } catch (error) {
                console.error('하위2 네비게이션 항목 수정 에러:', error);
                alert('하위2 네비게이션 항목 수정 중 에러가 발생했습니다.');
            }
        },
        cancelEditNav() {
            this.editModeNav = false;
            this.editIdNav = null;
            this.editTitleNav = '';
            this.editLinkNav = '';
        },
        cancelEditSub() {
            this.editModeSub = false;
            this.editIdSub = null;
            this.editTitleSub = '';
            this.editLinkSub = '';
        },
        cancelEditSub2() {
            this.editModeSub2 = false;
            this.editIdSub2 = null;
            this.editTitleSub2 = '';
            this.editLinkSub2 = '';
        },
        getParentTitle(parentId) {
            const parent = this.navigationItems.find(item => item.id === parentId);
            return parent ? parent.title : '';
        },
        getSubParentTitle(parentId) {
            const parent = this.subNavigationItems.find(item => item.id === parentId);
            return parent ? parent.title : '';
        }
    },
    async mounted() {
        await this.fetchNavigationItems();
        await this.fetchSubNavigationItems();
        await this.fetchSub2NavigationItems();
    }
};
</script>

<style scoped>
.wrap {
    padding: 20px;
}

table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

th,
td {
    padding: 10px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}
</style>
