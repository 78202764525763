<template>
    <div>
        <h2>학습자료 수정</h2>
        <label for="title">글 제목:</label>
        <input type="text" v-model="title" id="title" required />

        <label for="content">글 내용:</label>
        <ckeditor :editor="editor" v-model="content" :config="editorConfig"></ckeditor>

        <div v-if="existingFile">
            <p>첨부 파일: <a :href="`${API_BASE_URL}/uploads/${existingFile}`" target="_blank">{{ existingFile }}</a></p>
            <button @click="removeFile">파일 삭제</button>
        </div>

        <label for="file">새 파일 업로드 (필요시):</label>
        <input type="file" @change="handleFileUpload" />

        <button @click="updateMaterial">저장</button>
        <button @click="cancelEdit">취소</button>
    </div>
</template>

<script>
import axios from 'axios';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'StudyMaterialEdit',
    components: {
        ckeditor: Ckeditor,
    },
    data() {
        return {
            id: this.$route.params.id, // 수정할 학습자료의 ID
            title: '',
            content: '',
            existingFile: null, // 기존 첨부 파일 정보
            newFile: null, // 새로 업로드할 파일
            editor: ClassicEditor,
            API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
        };
    },
    computed: {
        editorConfig() {
            return {
                toolbar: [
                    'heading', '|', 'bold', 'italic', 'link',
                    'bulletedList', 'numberedList', 'blockQuote', 'imageUpload',
                ],
                ckfinder: {
                    uploadUrl: `${this.API_BASE_URL}/ckeditor/upload`,
                },
            };
        },
    },
    mounted() {
        this.fetchMaterial(); // 페이지가 로드될 때 학습자료 데이터를 불러옴
    },
    methods: {
        async fetchMaterial() {
            try {
                const response = await axios.get(`${this.API_BASE_URL}/data/detail`, {
                    params: { id: this.id }
                });
                const material = response.data.data;
                this.title = material.title; // 기존 제목을 불러옴
                this.content = material.content; // 기존 내용을 불러옴
                this.existingFile = material.file; // 기존 파일 정보를 저장
            } catch (error) {
                console.error('Error fetching material:', error);
            }
        },
        handleFileUpload(event) {
            this.newFile = event.target.files[0]; // 새로 업로드할 파일 저장
        },
        removeFile() {
            this.existingFile = null; // 기존 파일 정보 제거
        },
        async updateMaterial() {
            try {
                if (!this.title || !this.content.trim()) {
                    alert('제목과 내용을 모두 입력해 주세요.');
                    return;
                }

                const formData = new FormData();
                formData.append('title', this.title);
                formData.append('content', this.content);
                formData.append('file', this.newFile);
                formData.append('existingFile', this.existingFile);

                const response = await axios.put(`${this.API_BASE_URL}/data/${this.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                if (response.data.success) {
                    alert('학습자료가 성공적으로 수정되었습니다.');
                    this.$router.push({ name: 'StudyMaterialList' }); // 수정 후 목록 페이지로 이동
                } else {
                    alert('학습자료 수정에 실패했습니다.');
                }
            } catch (error) {
                console.error('Error updating material:', error);
                alert('학습자료 수정 중 오류가 발생했습니다.');
            }
        },
        cancelEdit() {
            this.$router.push({ name: 'StudyMaterialList' }); // 수정 취소시 목록 페이지로 이동
        },
    },
};
</script>

<style scoped>
button {
    padding: 10px 20px;
    margin-right: 10px;
}
</style>
