<template>
    <div>
        <h2>FAQ 목록</h2>
        <table>
            <thead>
                <tr>
                    <th>번호</th>
                    <th>제목</th>
                    <th>수정</th>
                    <th>삭제</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(faq, index) in faqs" :key="faq.id">
                    <td>{{ index + 1 }}</td>
                    <td @click="viewFaqDetail(faq.id)" style="cursor: pointer; color: blue;">{{ faq.title }}</td>
                    <td><button @click="editFaq(faq.id)">수정</button></td>
                    <td><button @click="deleteFaq(faq.id)">삭제</button></td>
                </tr>
            </tbody>
        </table>
        <button @click="goinsert()">추가</button>
    </div>
</template>

<script>
import axios from 'axios';


export default {
    name: 'FaqList',
    data() {
        return {
            faqs: [], // FAQ 데이터를 저장할 배열
            API_BASE_URL: process.env.VUE_APP_API_BASE_URL, // API URL
        };
    },
    mounted() {
        this.fetchFaqs(); // 컴포넌트가 마운트될 때 FAQ 데이터를 가져옴
    },
    methods: {
        goinsert() {
            this.$router.push({ name: 'faqinsert' });
        },
        viewFaqDetail(id) {
            this.$router.push({ name: 'FaqDetail', params: { id } }); // 상세보기 페이지로 이동
        },
        async fetchFaqs() {
            try {
                const response = await axios.get(`${this.API_BASE_URL}/faq`);
                this.faqs = response.data.data.reverse(); // 데이터를 역순으로 정렬하여 faqs 배열에 저장
            } catch (error) {
                console.error('Error fetching FAQs:', error);
            }
        },
        editFaq(id) {
            console.log(id);
            this.$router.push({ name: 'faqedit', params: { id } }); // 수정 페이지로 이동
        },
        async deleteFaq(id) {
            if (!confirm('정말로 이 FAQ를 삭제하시겠습니까?')) return;

            try {
                const response = await axios.delete(`${this.API_BASE_URL}/faq/${id}`);
                if (response.data.success) {
                    this.faqs = this.faqs.filter(faq => faq.id !== id); // 삭제된 항목을 목록에서 제거
                    alert('FAQ가 성공적으로 삭제되었습니다.');
                } else {
                    alert('FAQ 삭제에 실패했습니다.');
                }
            } catch (error) {
                console.error('Error deleting FAQ:', error);
                alert('FAQ 삭제 중 오류가 발생했습니다.');
            }
        },
    },
};
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

button {
    padding: 5px 10px;
}
</style>
