<template>
    <div class="login">
        <h2>Login</h2>
        <form @submit.prevent="login">
            <div>
                <label for="userid">아이디</label>
                <input type="text" v-model="userid" required>
            </div>
            <div>
                <label for="password">비밀번호</label>
                <input type="password" v-model="password" required>
            </div>
            <button type="submit">Login</button>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'mLogin',
    data() {
        return {
            userid: '',
            password: ''
        };
    },
    methods: {
        async login() {
            try {
                const response = await axios.post(`${API_BASE_URL}/auth/login`, {
                    userid: this.userid,
                    password: this.password
                });
                if (response && response.data) {

                    localStorage.setItem('accessToken', response.data.accessToken);
                    localStorage.setItem('refreshToken', response.data.refreshToken);
                    localStorage.setItem('userid', this.userid);
                    localStorage.setItem('id', response.data.user.id);
                    alert('로그인 성공');
                    this.$emit('login');
                    this.$router.push({ name: 'my_edu', query: { parent: '30', edu_study_mode: 'ing' } });
                } else {
                    alert('로그인 실패: 응답이 올바르지 않습니다.');
                }
            } catch (error) {
                if (error.response && error.response.data && error.response.data.msg) {
                    alert('로그인 실패: ' + error.response.data.msg);
                } else {
                    alert('로그인 실패: ' + error.message);
                }
            }
        }
    }
}
</script>

<style scoped>
.login {
    max-width: 300px;
    margin: 0 auto;
}
</style>
