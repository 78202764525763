<template>
    <div>
        <h1>{{ notice.title }}</h1>
        <p><strong>작성자:</strong> {{ notice.author }}</p>
        <p><strong>등록일:</strong> {{ formattedDate }}</p>
        <p><strong>조회수:</strong> {{ notice.views }}</p> <!-- 조회수 표시 -->
        <p><strong>내용:</strong></p>
        <div v-html="notice.content"></div>
        <p v-if="notice.file"><strong>첨부파일:</strong> <a :href="fileUrl" :download="notice.file">{{ notice.file }}</a></p>
        <router-link to="/">목록으로 돌아가기</router-link>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'NoticeDetail',
    data() {
        return {
            notice: {},
            param_id: "",
        };
    },
    computed: {
        formattedDate() {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            };
            return new Date(this.notice.created_at).toLocaleString('ko-KR', options);
        },
        fileUrl() {
            return `${API_BASE_URL}/notice/download/${this.notice.file}`;
        }
    },
    mounted() {
        this.fetchNotice();
    },
    methods: {
        async fetchNotice() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                this.param_id = urlParams.get('id');
                const response = await axios.get(`${API_BASE_URL}/data/detail`, {
                    params: { id: this.param_id }
                });
                if (response.data.success) {
                    this.notice = response.data.data;
                } else {
                    alert('공지사항 조회 실패');
                }
            } catch (error) {
                console.error('공지사항 조회 중 에러 발생:', error);
                alert('공지사항 조회 중 에러가 발생했습니다.');
            }
        }
    }
};
</script>

<style scoped></style>
