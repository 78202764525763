<template>
    <div class="wrap">
        <div v-if="users.length">
            <h2>사용자 목록</h2>
            <ul>
                <li v-for="user in users" :key="user.userid">
                    <input type="checkbox" :value="user.id" v-model="selectedUsers">&nbsp;
                    {{ user.username }} ({{ user.userid }}) - 생년월일: {{ user.birthday }}
                </li>
            </ul>
            <p>선택된 유저 ID: {{ selectedUsers }}</p>
        </div>
        <div v-else>
            <p>사용자 데이터를 불러오는 중...</p>
        </div>

        <div v-if="lecture.length">
            <h2>과정 목록</h2>
            <ul>
                <li v-for="item in lecture" :key="item.id">
                    <input type="checkbox" :value="item.id" v-model="selectedLectures">&nbsp;
                    과정명 : {{ item.course_name }} | 시작일: {{ item.start_date }} - 종료일: {{ item.end_date }}
                </li>
            </ul>
            <p>선택된 과정 ID: {{ selectedLectures }}</p>
        </div>
        <div v-else>
            <p>과정 데이터를 불러오는 중...</p>
        </div>
        <br>
        <div>
            <p>수강기간</p>
            <input type="date" v-model="startDate">~<input type="date" v-model="endDate">
        </div>
        <div>
            <p>실시회차</p>
            <input type="number" v-model="sessionNumber">
            <p>교강사</p>
            <select v-model="selectedTutor">
                <option v-for="tutor in tutors" :key="tutor.id" :value="tutor.id">
                    {{ tutor.name }} ({{ tutor.phone_number }})
                </option>
            </select>
        </div>
        <br>
        <button @click="enrollUsers">등록</button>
    </div>
</template>

<script>
import axios from 'axios';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'Edu_Lecture',
    data() {
        return {
            users: [],
            lecture: [],
            tutors: [], // 교강사 목록
            selectedUsers: [],   // 선택된 유저의 ID를 저장하는 배열
            selectedLectures: [], // 선택된 과정의 ID를 저장하는 배열
            selectedTutor: null, // 선택된 교강사 ID
        };
    },

    methods: {
        async fetchTutors() {
            try {
                const response = await axios.get(`${API_BASE_URL}/tutor/tutors`);
                if (response.status === 200) {
                    this.tutors = response.data.data;
                    console.log(this.tutors, "교강사");
                } else {
                    alert('교강사 목록을 불러오는 데 실패했습니다.');
                }
            } catch (error) {
                console.error('교강사 목록 조회 에러:', error);
                alert('교강사 목록을 불러오는 중 에러가 발생했습니다.');
            }
        },
        async fetchUsers() {
            try {
                const response = await axios.get(`${API_BASE_URL}/member/alluser`);
                if (response.status === 200) {
                    this.users = response.data;
                } else {
                    alert('사용자 목록을 불러오는 데 실패했습니다.');
                }
            } catch (error) {
                console.error('사용자 목록 조회 에러:', error);
                alert('사용자 목록을 불러오는 중 에러가 발생했습니다.');
            }
        },
        async fetchLecture() {
            try {
                const response = await axios.get(`${API_BASE_URL}/lecture`);
                if (response.status === 200) {
                    this.lecture = response.data.data;
                    console.log(this.lecture);
                } else {
                    alert('과정 목록을 불러오는 데 실패했습니다.');
                }
            } catch (error) {
                console.error('과정 목록 조회 에러:', error);
                alert('과정 목록을 불러오는 중 에러가 발생했습니다.');
            }
        },
        async enrollUsers() {
            if (this.selectedUsers.length && this.selectedLectures.length && this.selectedTutor) {
                try {

                    const response = await axios.post(`${API_BASE_URL}/edulecture/enroll`, {
                        userIds: this.selectedUsers,
                        courseId: this.selectedLectures[0], // 과정은 한 번에 하나만 선택 가능하다고 가정

                        startDate: this.startDate,
                        endDate: this.endDate,
                        tutorId: this.selectedTutor, // 선택된 교강사 ID 추가


                        sessionNumber: 1
                    });
                    if (response.status === 200) {
                        alert('등록 성공');
                    } else {
                        alert('등록 실패');
                    }
                } catch (error) {
                    console.error('등록 에러:', error);
                    alert('등록 중 에러가 발생했습니다.');
                }
            } else {
                alert('유저와 과정을 선택하세요.');
            }
        }
    },
    watch: {
        selectedUsers(newVal) {
            console.log('Selected User IDs:', newVal);
        },
        selectedLectures(newVal) {
            console.log('Selected Lecture IDs:', newVal);
        }
    },
    mounted() {
        this.fetchUsers();
        this.fetchLecture();
        this.fetchTutors(); // 교강사 데이터 가져오기
    }
};
</script>

<style scoped>
.wrap {
    padding: 20px;
    font-family: Arial, sans-serif;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>
