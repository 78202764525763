<template>
    <div>
        <subbar />
        <div class="faq_inner_warn">
            <ul>
                <li v-for="(faq, index) in faqs" :key="index">
                    <table class="faq_table1" @click="toggleContent(index)">
                        <colgroup>
                            <col width="10%" />
                            <col width="90%" />
                        </colgroup>
                        <tbody>
                            <tr class="bd_bt">
                                <td class="bd_image"><img src="../../assets/q.jpg" alt="Q"></td>
                                <td class="bd_text">{{ faq.title }}</td> <!-- 제목 출력 -->
                            </tr>
                        </tbody>
                    </table>

                    <table class="faq_table2" v-if="isOpen(index)">
                        <colgroup>
                            <col width="10%" />
                            <col width="90%" />
                        </colgroup>
                        <tbody>
                            <tr class="bd_ans_wrap">
                                <td class="bd_image"><img src="../../assets/a.jpg" alt="A"></td>
                                <td class="bd_answer" v-html="faq.content"></td> <!-- 내용 출력 (HTML 렌더링) -->
                            </tr>
                        </tbody>
                    </table>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import subbar from '../../components/section/subbar.vue';

export default {
    name: 'MenufaqPage',
    data() {
        return {
            faqs: [], // FAQ 데이터를 저장할 배열
            openIndex: null, // 현재 열려 있는 FAQ의 인덱스
            API_BASE_URL: process.env.VUE_APP_API_BASE_URL, // API URL
        };
    },
    components: {
        subbar,
    },
    mounted() {
        this.fetchFaqs(); // 컴포넌트가 마운트될 때 FAQ 데이터를 가져옴
    },
    methods: {
        async fetchFaqs() {
            try {
                const response = await axios.get(`${this.API_BASE_URL}/faq`); // API 호출
                this.faqs = response.data.data; // 데이터를 faqs 배열에 저장
                console.log(this.faqs, "faq");
            } catch (error) {
                console.error('Error fetching FAQs:', error);
            }
        },
        toggleContent(index) {
            // 이미 열려 있는 항목을 클릭하면 닫기
            if (this.openIndex === index) {
                this.openIndex = null;
            } else {
                // 다른 항목을 클릭하면 해당 항목 열기
                this.openIndex = index;
            }
        },
        isOpen(index) {
            return this.openIndex === index;
        }
    },
};
</script>

<style>
@import "../../css/faq.css";
@import "../../css/pcsize.css";

.mobileshow {
    display: none;
}
</style>
