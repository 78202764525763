<template>
    <div class="evaluation-management">
        <h1>{{ evaluationTitle }}</h1>
        <button @click="openModal()">문제 추가</button>

        <table>
            <thead>
                <tr>
                    <th>출력순서</th>
                    <th>질문 유형</th>
                    <th>질문</th>
                    <th>정답</th>
                    <th>해설</th>
                    <th>작업</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(question, index) in questions" :key="question.id">
                    <td>{{ question.display_order }}</td>
                    <td>{{ formatQuestionType(question.question_type) }}</td>
                    <td>{{ question.question }}</td>
                    <td>{{ question.correct_answer }}</td>
                    <td>{{ question.explanation }}</td>
                    <td>
                        <button @click="editQuestion(index)">수정</button>
                        <button @click="deleteQuestion(question.id)">삭제</button>
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- Modal for Adding/Editing Questions -->
        <div v-if="isModalOpen" class="modal_overlay" @click="closeModal">
            <div class="modal_content" @click.stop>
                <h3>{{ isEditMode ? '문제 수정' : '문제 추가' }}</h3>
                <form @submit.prevent="saveQuestion">
                    <div>
                        <label>출력순서:</label>
                        <input type="number" v-model="formData.display_order" required>
                    </div>

                    <div>
                        <label>질문 유형:</label>
                        <select v-model="formData.question_type" @change="handleQuestionTypeChange" required>
                            <option value="true_false">진위형</option>
                            <option value="short_answer">단답형</option>
                            <option value="multiple_choice">객관식</option>
                            <option value="essay">서술형</option>
                        </select>
                    </div>

                    <div>
                        <label>질문:</label>
                        <textarea v-model="formData.question" required></textarea>
                    </div>

                    <div v-if="formData.question_type === 'multiple_choice' || formData.question_type === 'true_false'">
                        <label>보기:</label>
                        <div v-for="(option, index) in formData.options" :key="index">
                            <input v-model="formData.options[index]" :disabled="formData.question_type === 'true_false'"
                                required>
                        </div>
                    </div>

                    <div>
                        <label>정답:</label>
                        <input
                            v-if="formData.question_type !== 'multiple_choice' && formData.question_type !== 'true_false'"
                            type="text" v-model="formData.correct_answer" required>
                        <select v-if="formData.question_type === 'multiple_choice'" v-model="formData.correct_answer"
                            required>
                            <option v-for="(option, index) in formData.options" :key="index" :value="option">{{ option }}
                            </option>
                        </select>
                        <select v-if="formData.question_type === 'true_false'" v-model="formData.correct_answer" required>
                            <option value="O">O</option>
                            <option value="X">X</option>
                        </select>
                    </div>

                    <div>
                        <label>해설:</label>
                        <textarea v-model="formData.explanation"></textarea>
                    </div>

                    <div>
                        <button type="submit">{{ isEditMode ? '수정' : '추가' }}</button>
                        <button type="button" @click="closeModal">취소</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
    name: 'LectureList',
    data() {

        return {

            lectureId: this.$route.query.id,
            evaluationType: this.$route.query.type, // 'midterm', 'final', 'task'
            evaluationTitle: '',
            questions: [],
            isModalOpen: false,
            isEditMode: false,
            formData: {
                display_order: 1,
                question_type: 'true_false',
                question: '',
                correct_answer: '',
                explanation: '',
                options: ['O', 'X'] // default for true_false
            },
            editIndex: null
        };
    },
    created() {
        this.evaluationTitle = this.getEvaluationTitle(this.evaluationType);
        this.fetchQuestions();
    },
    methods: {
        getEvaluationTitle(type) {
            switch (type) {
                case 'midterm': return '중간평가 관리';
                case 'final': return '최종평가 관리';
                case 'task': return '과제평가 관리';
                default: return '평가 관리';
            }
        },
        async fetchQuestions() {
            try {
                const response = await axios.get(`${API_BASE_URL}/evaluationQuestions?lecture_id=${this.lectureId}&evaluation_type=${this.evaluationType}`);
                this.questions = response.data;
                console.log(this.questions, "sad");
            } catch (error) {
                console.error('질문 목록을 가져오는 중 오류 발생:', error);
            }
        },
        openModal() {
            this.resetFormData();
            this.isModalOpen = true;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        resetFormData() {
            this.formData = {
                display_order: this.questions.length + 1,
                question_type: 'true_false',
                question: '',
                correct_answer: '',
                explanation: '',
                options: ['O', 'X']
            };
            this.isEditMode = false;
            this.editIndex = null;
        },
        handleQuestionTypeChange() {
            if (this.formData.question_type === 'multiple_choice') {
                this.formData.options = ['', '', '', '', '']; // 5 options for multiple choice
            } else if (this.formData.question_type === 'true_false') {
                this.formData.options = ['O', 'X']; // 2 options for true/false
            } else {
                this.formData.options = []; // No options for essay or short answer
            }
        },
        async saveQuestion() {
            try {
                if (this.isEditMode) {
                    await axios.put(`${API_BASE_URL}/evaluationQuestions/${this.questions[this.editIndex].id}`, this.formData);
                } else {
                    await axios.post(`${API_BASE_URL}/evaluationQuestions`, {
                        lecture_id: this.lectureId,
                        evaluation_type: this.evaluationType,
                        ...this.formData
                    });
                }
                this.fetchQuestions();
                this.closeModal();
            } catch (error) {
                console.error('질문을 저장하는 중 오류 발생:', error);
            }
        },
        editQuestion(index) {
            this.formData = { ...this.questions[index] };
            if (this.formData.question_type === 'true_false') {
                this.formData.options = ['O', 'X']; // 진위형 문제는 항상 'O'와 'X'만 옵션으로 설정
            }
            this.isEditMode = true;
            this.editIndex = index;
            this.isModalOpen = true;
        },
        async deleteQuestion(id) {
            if (confirm('정말로 이 질문을 삭제하시겠습니까?')) {
                try {
                    await axios.delete(`${API_BASE_URL}/evaluationQuestions/${id}`);
                    this.fetchQuestions();
                } catch (error) {
                    console.error('질문을 삭제하는 중 오류 발생:', error);
                }
            }
        },
        formatQuestionType(type) {
            switch (type) {
                case 'true_false': return '진위형';
                case 'short_answer': return '단답형';
                case 'multiple_choice': return '객관식';
                case 'essay': return '서술형';
                default: return '알 수 없음';
            }
        }
    }
};
</script>

<style scoped>
@import "../../../css/lectureinsert.css";

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f4f4f4;
}

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal_content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 500px;
}

.modal_content form div {
    margin-bottom: 15px;
}

.modal_content label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.modal_content input[type="text"],
.modal_content input[type="number"],
.modal_content textarea,
.modal_content select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    padding: 10px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

button+button {
    margin-left: 10px;
    background-color: #6c757d;
}

button+button:hover {
    background-color: #5a6268;
}
</style>
