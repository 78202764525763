<template>
    <div>
        <h2>FAQ 수정</h2>
        <label for="title">글 제목:</label>
        <input type="text" v-model="title" id="title" required />

        <label for="content">글 내용:</label>
        <ckeditor :editor="editor" v-model="content" :config="editorConfig"></ckeditor>

        <button @click="updateFaq">저장</button>
        <button @click="cancelEdit">취소</button>
    </div>
</template>

<script>
import axios from 'axios';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    name: 'FaqEdit',
    components: {
        ckeditor: Ckeditor,
    },
    data() {
        return {
            id: this.$route.params.id, // 수정할 FAQ의 ID
            title: '',
            content: '',
            editor: ClassicEditor,
            API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
        };
    },
    computed: {
        editorConfig() {
            return {
                toolbar: [
                    'heading', '|', 'bold', 'italic', 'link',
                    'bulletedList', 'numberedList', 'blockQuote', 'imageUpload',
                ],
                ckfinder: {
                    uploadUrl: `${this.API_BASE_URL}/ckeditor/upload`,
                },
            };
        },
    },
    mounted() {
        this.fetchFaq(); // 페이지가 로드될 때 FAQ 데이터를 불러옴
    },
    methods: {
        async fetchFaq() {
            try {

                const response = await axios.get(`${this.API_BASE_URL}/faq/${this.id}`);
                const faq = response.data.data;
                this.title = faq.title; // 기존 제목을 불러옴
                this.content = faq.content; // 기존 내용을 불러옴
            } catch (error) {
                console.error('Error fetching FAQ:', error);
            }
        },
        async updateFaq() {
            try {
                if (!this.title || !this.content.trim()) {
                    alert('제목과 내용을 모두 입력해 주세요.');
                    return;
                }

                const response = await axios.put(`${this.API_BASE_URL}/faq/${this.id}`, {
                    title: this.title,
                    content: this.content,
                });

                if (response.data.success) {
                    alert('FAQ가 성공적으로 수정되었습니다.');
                    this.$router.push({ name: 'faqlist' }); // 수정 후 목록 페이지로 이동
                } else {
                    alert('FAQ 수정에 실패했습니다.');
                }
            } catch (error) {
                console.error('Error updating FAQ:', error);
                alert('FAQ 수정 중 오류가 발생했습니다.');
            }
        },
        cancelEdit() {
            this.$router.push({ name: 'FaqList' }); // 수정 취소시 목록 페이지로 이동
        },
    },
};
</script>

<style scoped>
button {
    padding: 10px 20px;
    margin-right: 10px;
}
</style>
