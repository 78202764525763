<template>
    <div id="appsad">
        <div class="bumo" @mousemove="resetTimers" @keydown="resetTimers" @click="resetTimers">
            <TopMenu :isAuthenticated="isAuthenticated" :timeLeft="timeLeft" :userInfo="userInfo" @logout="handleLogout" />
            <subMenu :isAuthenticated="isAuthenticated" :timeLeft="timeLeft" />

            <navMenu :isAuthenticated="isAuthenticated" :timeLeft="timeLeft" class="nav-menu">
                <div class="sub-menu">
                    <!-- 서브메뉴 내용 -->
                </div>
            </navMenu>

            <router-view @user-activity="resetTimers" @login="handleLogin" class="router-view"></router-view>

            <Footer class="footer" />
        </div>
    </div>
</template>

<script>
import Footer from '../components/section/footer.vue';
import TopMenu from '../components/section/TopMenu.vue';
import subMenu from '../components/section/subMenu.vue';
import navMenu from '../components/section/navMenu.vue';
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'DefaultLayout',
    components: {
        Footer,
        TopMenu,
        subMenu,
        navMenu
    },
    data() {
        return {
            isAuthenticated: false,
            timeLeft: 7200,
            timer: null,
            logoutTimer: null,
            userInfo: { username: 'Guest' } // 기본값 설정
        };
    },

    methods: {
        async handleLogin() {
            this.isAuthenticated = true;
            await this.fetchUserInfo();
            this.startTimers();
            this.verifyTokenPeriodically();
        },
        async handleLogout() {
            const refreshToken = localStorage.getItem('refreshToken');
            try {
                await axios.post(`${API_BASE_URL}/auth/logout`, { refreshToken });
                this.isAuthenticated = false;
                this.userInfo = { username: 'Guest' }; // 로그아웃 시 기본값으로 설정
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('userid');
                localStorage.removeItem('id');
                this.clearTimers();
                this.$router.push({ name: 'mLogin' });
            } catch (error) {
                console.error('로그아웃 실패:', error);
            }
        },
        async fetchUserInfo() {
            try {
                const token = localStorage.getItem('accessToken');
                const uid = localStorage.getItem('userid');
                const response = await axios.get(`${API_BASE_URL}/protected/userinfo`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        userid: uid
                    }
                });
                this.userInfo = response.data;
            } catch (error) {
                console.error('Failed to fetch user info:', error);
                this.handleLogout();
            }
        },
        startTimers() {
            this.clearTimers();
            this.startTimer();
            this.startLogoutTimer();
        },
        startTimer() {
            this.timer = setInterval(() => {
                if (this.timeLeft > 0) {
                    this.timeLeft--;
                } else {
                    clearInterval(this.timer);
                }
            }, 1000);
        },
        startLogoutTimer() {
            this.logoutTimer = setTimeout(() => {
                this.handleLogout();
                alert("세션이 만료되어 로그아웃 되었습니다.");
            }, 7200000); // 2시간 = 7200000ms
        },
        clearTimers() {
            if (this.timer) {
                clearInterval(this.timer);
                this.timer = null;
            }
            if (this.logoutTimer) {
                clearTimeout(this.logoutTimer);
                this.logoutTimer = null;
            }
        },
        resetTimers() {
            if (this.isAuthenticated) {
                this.timeLeft = 7200;
                this.startTimers();
            }
        },
        async verifyTokenPeriodically() {
            const verifyToken = async () => {
                const token = localStorage.getItem('accessToken');
                const refreshToken = localStorage.getItem('refreshToken');
                if (token) {  // token 값이 있을 때만 실행
                    try {
                        await axios.post(`${API_BASE_URL}/auth/verify-token`, { token, refreshToken });
                        console.log("Token verified", "토큰비교 ");
                    } catch (error) {
                        console.error('에러', error);
                        alert("중복로그인으로 로그아웃되셨습니다")
                        this.handleLogout();
                    }
                }
            };

            setInterval(verifyToken, 5000); // 5초마다 토큰 유효성 확인
        }
    },
    async mounted() {
        const token = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        if (token && refreshToken) {
            try {
                await axios.post(`${API_BASE_URL}/auth/verify-token`, { token, refreshToken });
                this.handleLogin();
            } catch (error) {
                console.error('Token verification failed:', error);
                this.handleLogout();
            }
        }
    },
    beforeUnmount() {
        this.clearTimers();
    }
}
</script>

<style>
.bumo {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
}

.nav-menu {
    position: relative;
    z-index: 99;

}

.sub-menu {
    position: absolute;
    top: 100%;
    /* nav-menu 바로 아래에 위치 */
    left: 0;
    width: 100%;
    background-color: white;
    /* 필요에 따라 색상 지정 */
    z-index: 20;
    /* 다른 요소들보다 위에 표시 */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* 그림자 추가 */
    display: none;
    /* 기본적으로 서브메뉴는 숨김 */
}

.nav-menu:hover .sub-menu {
    display: block;
    /* nav-menu에 호버할 때 서브메뉴 표시 */
}

.router-view {
    flex: 1;
    position: relative;

    width: 100%;
    clear: both;
    box-sizing: border-box;
    overflow: auto;
}
</style>
