<template>
    <div class="app_game" id="app" @keydown="handleKeydown" tabindex="0">
        <h1>Tetris</h1>
        <div class="game-board">
            <div v-for="(row, rowIndex) in board" :key="rowIndex" class="game_row">
                <div v-for="(cell, cellIndex) in row" :key="cellIndex" :class="['cell', cell ? 'filled' : '']"></div>
            </div>
        </div>
        <button @click="startGame">Start Game</button>
    </div>
</template>

<script>
export default {
    name: 'FaqDetail',
    data() {
        return {
            board: this.createBoard(20, 10),
            currentPiece: null,
            intervalId: null,
            pieces: [
                [[1, 1, 1, 1]], // I
                [[1, 1], [1, 1]], // O
                [[0, 1, 0], [1, 1, 1]], // T
                [[1, 0, 0], [1, 1, 1]], // L
                [[0, 0, 1], [1, 1, 1]], // J
                [[0, 1, 1], [1, 1, 0]], // S
                [[1, 1, 0], [0, 1, 1]], // Z
            ],
        };
    },
    methods: {
        createBoard(rows, cols) {
            return Array.from({ length: rows }, () =>
                Array.from({ length: cols }, () => 0)
            );
        },
        startGame() {
            this.board = this.createBoard(20, 10);
            this.spawnPiece();
            if (this.intervalId) clearInterval(this.intervalId);
            this.intervalId = setInterval(this.gameLoop, 500);
            this.$el.focus();
        },
        spawnPiece() {
            const piece =
                this.pieces[Math.floor(Math.random() * this.pieces.length)];
            this.currentPiece = { shape: piece, row: 0, col: 3 };
            this.placePiece();
        },
        placePiece() {
            this.currentPiece.shape.forEach((row, rIdx) => {
                row.forEach((cell, cIdx) => {
                    if (cell) {
                        this.board[this.currentPiece.row + rIdx][
                            this.currentPiece.col + cIdx
                        ] = cell;
                    }
                });
            });
        },
        clearPiece() {
            const { shape, row, col } = this.currentPiece;
            shape.forEach((r, rIdx) => {
                r.forEach((cell, cIdx) => {
                    if (cell) {
                        this.board[row + rIdx][col + cIdx] = 0;
                    }
                });
            });
        },
        gameLoop() {
            this.movePieceDown();
        },
        movePieceDown() {
            if (this.canPieceMoveDown()) {
                this.clearPiece();
                this.currentPiece.row++;
                this.placePiece();
            } else {
                this.placePiece();
                this.spawnPiece();
            }
        },
        canPieceMoveDown() {
            const { shape, row, col } = this.currentPiece;
            return shape.every((r, rIdx) =>
                r.every((cell, cIdx) => {
                    if (!cell) return true;
                    const newRow = row + rIdx + 1;
                    return (
                        newRow < this.board.length &&
                        !this.board[newRow][col + cIdx]
                    );
                })
            );
        },
        movePiece(direction) {
            if (this.canMove(direction)) {
                this.clearPiece();
                this.currentPiece.col += direction;
                this.placePiece();
            }
        },
        rotatePiece() {
            const rotatedShape = this.currentPiece.shape[0].map((_, index) =>
                this.currentPiece.shape.map(row => row[index]).reverse()
            );
            const originalShape = this.currentPiece.shape;
            this.clearPiece();
            this.currentPiece.shape = rotatedShape;
            if (this.isValidPosition()) {
                this.placePiece();
            } else {
                this.currentPiece.shape = originalShape;
                this.placePiece();
            }
        },
        canMove(direction) {
            const { shape, row, col } = this.currentPiece;
            return shape.every((r, rIdx) =>
                r.every((cell, cIdx) => {
                    if (!cell) return true;
                    const newCol = col + direction + cIdx;
                    return (
                        newCol >= 0 &&
                        newCol < this.board[0].length &&
                        !this.board[row + rIdx][newCol]
                    );
                })
            );
        },
        isValidPosition() {
            const { shape, row, col } = this.currentPiece;
            return shape.every((r, rIdx) =>
                r.every((cell, cIdx) => {
                    if (!cell) return true;
                    const newRow = row + rIdx;
                    const newCol = col + cIdx;

                    return (
                        newRow >= 0 &&
                        newRow < this.board.length &&
                        newCol >= 0 &&
                        newCol < this.board[0].length &&
                        !this.board[newRow][newCol]
                    );
                })
            );
        },
        handleKeydown(event) {
            switch (event.key) {
                case 'ArrowLeft':
                    this.movePiece(-1);
                    break;
                case 'ArrowRight':
                    this.movePiece(1);
                    break;
                case 'ArrowDown':
                    this.movePieceDown();
                    break;
                case 'ArrowUp':
                    this.rotatePiece();
                    break;
            }
        },
    },
    beforeUnmount() {
        if (this.intervalId) clearInterval(this.intervalId);
    },
};
</script>

<style>
@import "../../../css/game.css";
</style>
