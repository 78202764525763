<template>
    <div class="fullbg" @mousemove="notifyParent" @keydown="notifyParent" @click="notifyParent">
        <div class="nav_1full">
            <div class="nav_1">
                <ul>
                    <li v-for="item in navigationItems" :key="item.id" @mouseenter="showDropdownGlobal"
                        @mouseleave="hideDropdownGlobal">
                        <a :href="item.link">{{ item.title }}</a>
                        <ul v-if="isDropdownVisible[item.id]" class="dropdown">
                            <li v-for="child in getSubItems(item.id)" :key="child.id">
                                <a :href="child.link">{{ child.title }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div class="drop_full" :class="{ active: isDropdownVisibleGlobal }" @mouseenter="showDropdownGlobal"
            @mouseleave="hideDropdownGlobal">
            <div class="drop_1">
                <ul class="azffs">
                    <li class="azss" v-for="item in navigationItems" :key="item.id">
                        <ul>
                            <li class="none" v-for="child in getSubItems(item.id)" :key="child.id">
                                <a class="submenu_child" :href="child.link">{{ child.title }}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'navMenu',
    data() {
        return {
            isDropdownVisibleGlobal: false,
            isDropdownVisible: {},
            navigationItems: [],
            subNavigationItems: [],
        };
    },
    computed: {
        getSubItems() {
            return (parentId) => this.subNavigationItems.filter(item => item.parent_id === parentId);
        }
    },
    methods: {
        notifyParent() {
            this.$emit('user-activity');
        },
        showDropdown(id) {
            this.isDropdownVisible = { ...this.isDropdownVisible, [id]: true };
        },
        hideDropdown(id) {
            this.isDropdownVisible = { ...this.isDropdownVisible, [id]: false };
        },
        showDropdownGlobal() {
            this.isDropdownVisibleGlobal = true;
        },
        hideDropdownGlobal() {
            this.isDropdownVisibleGlobal = false;
        },
        async fetchNavigationItems() {
            try {
                const response = await axios.get(`${API_BASE_URL}/navigation`);
                if (response.data.success) {
                    this.navigationItems = response.data.data;
                    this.updateItemWidths(); // 네비게이션 항목을 가져온 후 너비를 업데이트합니다.
                } else {
                    alert('네비게이션 항목 조회 실패.');
                }
            } catch (error) {
                console.error('네비게이션 항목 조회 에러:', error);
                alert('네비게이션 항목 조회 중 에러가 발생했습니다.');
            }
        },
        async fetchSubNavigationItems() {
            try {
                const response = await axios.get(`${API_BASE_URL}/navigation/sub-navigation`);
                if (response.data.success) {
                    this.subNavigationItems = response.data.data;
                    this.updateheight();
                } else {
                    alert('하위 네비게이션 항목 조회 실패.');
                }
            } catch (error) {
                console.error('하위 네비게이션 항목 조회 에러:', error);
                alert('하위 네비게이션 항목 조회 중 에러가 발생했습니다.');
            }
        },
        updateheight() {
            const parentIdCount = this.subNavigationItems.reduce((acc, item) => {
                acc[item.parent_id] = (acc[item.parent_id] || 0) + 1;
                return acc;
            }, {});

            let maxCount = 0;
            let maxParentId = null;
            for (const parentId in parentIdCount) {
                if (parentIdCount[parentId] > maxCount) {
                    maxCount = parentIdCount[parentId];
                    maxParentId = parentId;
                }
            }
            console.log('갯수:', maxCount);
            console.log(maxParentId);
            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `
                .azss {
                    height: ${maxCount * 35}px;
                }
            `;
            document.head.appendChild(style);
        },
        updateItemWidths() {
            const navItemsCount = this.navigationItems.length;
            const widthPercentage = 100 / navItemsCount;

            const style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML = `
                .nav_1 ul li, .azss {
                    width: ${widthPercentage}%;
                }
            `;
            document.head.appendChild(style);
        }
    },
    async mounted() {
        await this.fetchNavigationItems();
        await this.fetchSubNavigationItems();
    }
};
</script>

<style scoped>
@import "../../css/navMenu.css";
@import "../../css/pcsize.css";
</style>
