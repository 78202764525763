<template>
    <div>
        <form @submit.prevent="addMaterial">
            <select v-model="newMaterial.category" required>
                <option v-for="category in filteredCatelist" :key="category.id" :value="category.num">{{ category.title }}
                </option>
            </select>
            <input v-model="newMaterial.title" placeholder="제목" required>
            <input v-model="newMaterial.author" placeholder="작성자" required>

            <ckeditor :editor="editor" v-model="newMaterial.content" :config="editorConfig"></ckeditor>
            <input type="file" @change="handleFileUpload">

            <br><br>
            <button type="submit">완료</button>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'LearningMaterial',
    components: {
        ckeditor: Ckeditor, // CKEditor 대신 Ckeditor로 변경
    },
    data() {
        return {
            items: [],
            catelist: [],
            sub2NavigationItems: [],
            searchKeyword: '',
            par_id: 31,
            category_code: "",
            newMaterial: {
                category: '',
                title: '',
                author: '',
                content: '',
                file: null
            },
            currentPage: 1,
            pageSize: 10,
            editor: ClassicEditor,
            editorConfig: {
                toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'imageUpload'],
                ckfinder: {
                    uploadUrl: `${API_BASE_URL}/ckeditor/upload`,
                },
            }
        };
    },
    computed: {
        paginatedItems() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.processedItems.slice(start, end);
        },
        processedItems() {
            return this.items.map((item, index) => {
                return {
                    ...item,
                    category: this.getCategoryTitle(item.category),
                    number: index + 1
                };
            });
        },
        filteredCatelist() {
            return this.catelist.filter(category => category.num !== 1);
        },
        totalPages() {
            return Math.ceil(this.items.length / this.pageSize);
        }
    },
    mounted() {
        this.fetchData();
        this.fetchData2();
        this.$nextTick(() => {
            if (this.filteredCatelist.length > 0) {
                this.newMaterial.category = this.filteredCatelist[0].num;
            }
        });
    },
    methods: {
        async fetchData2() {
            try {
                const response = await axios.get(`${API_BASE_URL}/navigation/sub2-navigation`, {
                    params: { parentId: this.par_id }
                });
                if (response.data.success) {
                    this.catelist = response.data.data;
                    console.log(this.catelist, "asd");
                    this.$nextTick(() => {

                        if (this.filteredCatelist.length > 0) {
                            this.newMaterial.category = this.filteredCatelist[0].num;
                        }
                    });
                } else {
                    alert('데이터 조회 실패');
                }
            } catch (error) {
                console.error('데이터 조회 중 에러 발생:', error);
                alert('데이터 조회 중 에러가 발생했습니다.');
            }
        },
        async fetchData() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                this.categorycode = urlParams.get('category_code');
                const response = await axios.get(`${API_BASE_URL}/data`, {
                    params: { category: this.categorycode }
                });
                if (response.data.success) {
                    this.items = response.data.data;
                } else {
                    alert('데이터 조회 실패');
                }
            } catch (error) {
                console.error('데이터 조회 중 에러 발생:', error);
                alert('데이터 조회 중 에러가 발생했습니다.');
            }
        },
        handleFileUpload(event) {
            this.newMaterial.file = event.target.files[0];
        },
        async addMaterial() {
            try {
                const formData = new FormData();
                formData.append('category', this.newMaterial.category);
                formData.append('title', this.newMaterial.title);
                formData.append('author', this.newMaterial.author);
                formData.append('content', this.newMaterial.content);
                formData.append('file', this.newMaterial.file);

                const response = await axios.post(`${API_BASE_URL}/data`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data.success) {
                    alert('학습자료가 성공적으로 추가되었습니다.');
                    this.newMaterial = { category: '', title: '', author: '', content: '', file: null };
                    this.$nextTick(() => {
                        if (this.filteredCatelist.length > 0) {
                            this.newMaterial.category = this.filteredCatelist[0].num;
                        }
                    });
                    this.fetchData(); // 목록 갱신
                } else {
                    alert('학습자료 추가 실패');
                }
            } catch (error) {
                console.error('학습자료 추가 중 에러 발생:', error);
                alert('학습자료 추가 중 에러가 발생했습니다.');
            }
        },
        getCategoryTitle(categoryId) {
            const category = this.catelist.find(cat => cat.num === Number(categoryId));
            return category ? category.title : categoryId;
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        search() {
            // Implement search functionality if needed
        }
    }
};
</script>

<style scoped></style>
