<template>
    <div class="carousel" @mouseenter="pauseAutoPlay" @mouseleave="startAutoPlay">
        <div class="slides">
            <div class="slide" v-for="(image, index) in images" :key="index" :class="{ active: index === currentIndex }">
                <img class="carimg" :src="image.filepath" alt="banner image" />
            </div>
        </div>
        <button @click="prevSlide" class="nav prev">‹</button>
        <button @click="nextSlide" class="nav next">›</button>
        <div class="mainbanner">
            <div class="bannerarea1 mb35">

            </div>
            <div class="clear"></div>
            <div class="bannerarea">
                <ul>
                    <div class="banner02 aos-init aos-animate" data-aos="fade-up" data-aos-delay="500">
                        <p class="bannertt">PC원격지원</p>
                        <p class="bannertt1">실시간 간편하게 온라인으로 원격지원해 드립니다.</p>
                        <a href="https://367.co.kr/" target="_blank"><img src="/images/icon08.png" style="float:right"></a>
                    </div>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const IMAGE_BASE_URL = process.env.VUE_APP_IMAGE_BASE_URL;
export default {
    name: 'CarouselPage',
    data() {
        return {
            images: [],
            currentIndex: 0,
            interval: null
        }
    },
    mounted() {
        this.startAutoPlay();
        this.fetchFiles();
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    methods: {
        async fetchFiles() {
            try {
                const response = await axios.get(`${API_BASE_URL}/upload/files`);
                if (response.data.success) {
                    // 파일 경로를 images 배열에 추가
                    this.images = response.data.data.map(file => ({
                        filepath: `${IMAGE_BASE_URL}/${file.filepath}`
                    }));
                    console.log(this.images);
                } else {
                    alert('파일 목록 조회 실패.');
                }
            } catch (error) {
                console.error('파일 목록 조회 에러:', error);
                alert('파일 목록 조회 중 에러가 발생했습니다.');
            }
        },
        startAutoPlay() {
            if (this.interval) clearInterval(this.interval);
            this.interval = setInterval(this.nextSlide, 3000);
        },
        pauseAutoPlay() {
            clearInterval(this.interval);
        },
        nextSlide() {
            this.currentIndex = (this.currentIndex + 1) % this.images.length;
        },
        prevSlide() {
            this.currentIndex = (this.currentIndex - 1 + this.images.length) % this.images.length;
        }
    }
}
</script>

<style>
.mainbanner {
    position: absolute;
    height: 430px;
    z-index: 6;
    left: 0;
    width: 1300px;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -60%);
    text-align: right;
}

.mainbanner .bannerarea {
    width: 600px;
    border: 0px solid red;
    float: right;
    text-align: Center;
}

.banner02 {
    background: #cc9933;
    text-align: left;
    width: 48%;
    border-radius: 25px;
    float: right;
    padding: 20px 30px;
    box-sizing: border-box;
}

.carousel {
    position: relative;
    width: 100%;
    height: 590px;
    overflow: hidden;
    margin: auto;
}

.slides {
    position: relative;
    width: 100%;
    height: 100%;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.slide.active {
    opacity: 1;
}

.carimg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

/* 반응형 디자인 추가 */
@media (max-width: 768px) {
    .carousel {
        height: 300px;
    }

    .nav {
        font-size: 1.5rem;
    }
}
</style>
