<template>
    <div id="wrap">
        <div class="wrap_inner">
            <ul>
                <li v-if="isAuthenticated">{{ userInfo && userInfo.username ? userInfo.username : 'Guest' }}님이 접속하였습니다</li>
                <li v-else>로그인되지 않았습니다</li>
                <li>|</li>
                <li v-if="isAuthenticated"><span>{{ formattedTime }}</span> 남음</li>
                <li v-if="isAuthenticated">|</li>
                <li><a href="#">이용가이드</a></li>
                <li>|</li>
                <li><a href="#">고객센터</a></li>
                <li class="login_status" v-if="isAuthenticated" @click="logout">
                    <img class="icon_1" src="../../image/topicon01.png" alt="icon"> 로그아웃
                </li>
                <router-link to="/mLogin" v-else>
                    <li class="login_status">
                        <img class="icon_1" src="../../image/topicon01.png" alt="icon"> 로그인
                    </li>
                </router-link>
                <router-link to="/mJoin" v-if="isAuthenticated">
                    <li class="mypage_btn"><img class="icon_1" src="../../image/topicon02.png" alt="icon"> 마이페이지
                    </li>
                </router-link>
                <router-link to="/mJoin" v-else>
                    <li class="mypage_btn"><img class="icon_1" src="../../image/topicon02.png" alt="icon"> 회원가입
                    </li>
                </router-link>
            </ul>
        </div>
    </div>
</template>

<script>
//import axios from "axios";

export default {
    name: 'TopMenu',

    props: {
        isAuthenticated: {
            type: Boolean,
            required: true
        },
        timeLeft: {
            type: Number,
            required: true
        },
        userInfo: {
            type: Object,
            default: () => ({ username: 'Guest' }) // 기본값 설정
        }
    },
    data() {
        return { userid: "" }

    },
    computed: {
        formattedTime() {

            const hours = Math.floor(this.timeLeft / 3600);
            const minutes = Math.floor((this.timeLeft % 3600) / 60);
            const seconds = this.timeLeft % 60;
            return `${hours}시간 ${minutes}분 ${seconds}초`;
        }
    },
    methods: {
        logout() {
            this.$emit('logout');
        },



    },

};
</script>

<style>
@import "../../css/TopMenu.css";
@import "../../css/pcsize.css";
</style>
