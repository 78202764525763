<template>
    <div class="layer_wrap">
        <div class="layer_1 border_all">
            <div class="layer_1">
                <p>과정이미지</p>
                <div v-if="lecture.course_image">
                    <p>이미지:</p>
                    <img :src="`${API_BASE_URL}/uploads/${lecture.course_image}`" alt="Course Image" width="200" />
                </div>
            </div>
            <div class="layer_2">
                <p>과정명</p>
                <p class="input_text">{{ lecture.course_name }}</p>
                <p>과정분류</p>
                <p class="input_text">{{ lecture.fullPath }}</p>
                <p>간략소개</p>
                <p class="input_text">{{ lecture.course_description }}</p>
            </div>

            <hr>

            <div class="label_1">
                <p>옵션</p>
                <label class="lec_label">
                    환급: <span>{{ lecture.is_refundable ? 'Yes' : 'No' }}</span>
                </label>
                <label class="lec_label">
                    BEST: <span>{{ lecture.is_best ? 'Yes' : 'No' }}</span>
                </label>
                <label class="lec_label">
                    500인 초과: <span>{{ lecture.is_over_500 ? 'Yes' : 'No' }}</span>
                </label>
            </div>

            <div class="label_2">
                <ul class="bel2">
                    <li>
                        <span class="lecture_span">참여직무확인서</span>
                        <br>
                        <label class="lec_label">
                            출력: <span>{{ lecture.is_print_certificate ? 'Yes' : 'No' }}</span>
                        </label>
                    </li>
                    <li>
                        <span class="lecture_span">실습전용</span>
                        <br>
                        <label class="lec_label">
                            <span>{{ lecture.is_practice_only ? 'Yes' : 'No' }}</span>
                        </label>
                    </li>
                    <li>
                        <span class="lecture_span">실습필요</span>
                        <br>
                        <label class="toggle_label ">
                            <span class="lecture_span toggle_slider" :class="{ 'active': lecture.isPracticeRequired1 }">
                                {{ lecture.isPracticeRequired1 ? 'Yes' : 'No' }}
                            </span>
                        </label>
                    </li>
                    <li>
                        <span class="lecture_span">공급형태</span>
                        <br>
                        <label class="lec_label">
                            <span>{{ lecture.supply_type }}</span>
                        </label>
                    </li>
                </ul>
            </div>

            <hr>

            <div class="label_3">
                <ul class="bel2">
                    <li>
                        <span class="lecture_span">등급</span>
                        <br>
                        <label class="lec_label">
                            <span>{{ lecture.grade }}</span>
                        </label>
                    </li>
                    <li>
                        <span class="lecture_span">과정코드</span>
                        <br>
                        <label class="lec_label">
                            <span>{{ lecture.course_code }}</span>
                        </label>
                    </li>
                    <li>
                        <span class="lecture_span">심사코드</span>
                        <br>
                        <label class="lec_label">
                            <span>{{ lecture.review_code }}</span>
                        </label>
                    </li>
                    <li>
                        <span class="lecture_span">HRD-NET 과정코드</span>
                        <br>
                        <label class="lec_label">
                            <span>{{ lecture.hrd_net_course_code }}</span>
                        </label>
                    </li>
                </ul>
            </div>

            <hr>

            <ul class="bel3">
                <li>
                    <span class="lecture_span">교강사정보</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.instructor_info }}</span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">내용 전문가</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.content_expert }}</span>
                    </label>
                </li>
            </ul>

            <hr>

            <ul class="bel4">
                <li>
                    <span class="lecture_span">연간수강정원</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.annual_capacity }}명</span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">컨텐츠 등급 유효기간</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.content_grade_validity_start }} ~ {{ lecture.content_grade_validity_end }}</span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">인정기간</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.recognition_period_start }} ~ {{ lecture.recognition_period_end }}</span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">NCE 소분류코드</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.nce_subcategory_code }}</span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">NCE 소분류명</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.nce_subcategory_name }}</span>
                    </label>
                </li>
            </ul>

            <hr>

            <ul class="bel5">
                <li>
                    <span class="lecture_span">진도시간 기준</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.progress_time_standard }}</span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">1일 수강 차시 제한</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.daily_limit }}</span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">교육시간</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.education_time }}시간</span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">최종평가시간(분)</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.final_evaluation_time }}분</span>
                    </label>
                </li>
            </ul>

            <hr>

            <ul class="bel6">
                <li>
                    <span class="lecture_span">학습자료</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.study_material_path }}</span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">용어설명집</span>
                    <br>
                    <label class="lec_label">
                        <span>{{ lecture.glossary_path }}</span>
                    </label>
                </li>
            </ul>

            <hr>

            <ul class="bel7">
                <li>
                    <span class="lecture_span">이몬전송여부</span>
                    <br>
                    <label class="toggle_label">
                        <span class="lecture_span toggle_slider" :class="{ 'active': lecture.isPracticeRequired2 }">
                            {{ lecture.isPracticeRequired2 ? 'Yes' : 'No' }}
                        </span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">사용자노출</span>
                    <br>
                    <label class="toggle_label">
                        <span class="lecture_span toggle_slider" :class="{ 'active': lecture.isPracticeRequired3 }">
                            {{ lecture.isPracticeRequired3 ? 'Yes' : 'No' }}
                        </span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">토론방</span>
                    <br>
                    <label class="toggle_label">
                        <span class="lecture_span toggle_slider" :class="{ 'active': lecture.isPracticeRequired4 }">
                            {{ lecture.isPracticeRequired4 ? 'Yes' : 'No' }}
                        </span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">용어설명집</span>
                    <br>
                    <label class="toggle_label">
                        <span class="lecture_span toggle_slider" :class="{ 'active': lecture.isPracticeRequired5 }">
                            {{ lecture.isPracticeRequired5 ? 'Yes' : 'No' }}
                        </span>
                    </label>
                </li>
                <li>
                    <span class="lecture_span">CHAT GPT</span>
                    <br>
                    <label class="toggle_label">
                        <span class="lecture_span toggle_slider" :class="{ 'active': lecture.isPracticeRequired6 }">
                            {{ lecture.isPracticeRequired6 ? 'Yes' : 'No' }}
                        </span>
                    </label>
                </li>
            </ul>

            <hr>

            <ul class="bel8">
                <li style="width:20%">
                    <span class="lecture_span">메타버스 세미나</span>
                    <br>
                    <label class="toggle_label">
                        <span class="lecture_span toggle_slider" :class="{ 'active': lecture.isPracticeRequired7 }">
                            {{ lecture.isPracticeRequired7 ? 'Yes' : 'No' }}
                        </span>
                    </label>
                </li>
                <li style="width: 80%;">
                    <span class="lecture_span">메타버스 세미나 URL</span>
                    <br>
                    <label class="toggle_label">
                        <span>{{ lecture.metaverse_seminar_url }}</span>
                    </label>
                </li>
            </ul>
        </div>

        <div class="layer_2 border_all">
            <p class="l3_top">교육비용정보 & CP 정보</p>

            <table style="border-collapse: collapse; width:100%;">
                <colgroup>
                    <col width="20%">
                    <col width="10%">
                    <col width="23%">
                    <col width="23%">
                    <col width="23%">
                </colgroup>
                <tr class="tb_top">
                    <td>교육비용</td>
                    <td>구분</td>
                    <td>우선지원대상</td>
                    <td>(대)1,000인 이하</td>
                    <td>(대)1,000인 이상</td>
                </tr>
                <tr class="tb_bottom">
                    <td>{{ lecture.all_cost }}</td>
                    <td>환급비용</td>
                    <td>{{ lecture.refund_cost_priority_support }}</td>
                    <td>{{ lecture.refund_cost_under_1000 }}</td>
                    <td>{{ lecture.refund_cost_over_1000 }}</td>
                </tr>
                <tr class="tb_bottom">
                    <td></td>
                    <td>자부담금</td>
                    <td>{{ lecture.self_cost_priority_support }}</td>
                    <td>{{ lecture.self_cost_under_1000 }}</td>
                    <td>{{ lecture.self_cost_over_1000 }}</td>
                </tr>
            </table>

            <hr>

            <div class="edu_1">
                <span>교육비용 출력여부</span><br>
                <span>{{ lecture.cost_display_type === 'A' ? '반영 비율 표시' : '반영 비율 대체메세지 표시' }}</span>
            </div>
            <div class="edu_2">
                <span>교육비 대체메시지</span><br>
                <span>{{ lecture.cost_message }}</span>
            </div>

            <hr>

            <div class="edu_1">
                <span>CP사</span><br>
                <span>{{ lecture.cp_company_name }}</span>
            </div>
            <div class="edu_2">
                <span>CP수수료</span><br>
                <span>{{ lecture.cp_commission }}%</span>
            </div>

            <div class="edu_100">
                <p class="l3_top">수료정보</p>
                <span>수료증 회사이름</span><br>
                <span>{{ lecture.certificate_company_name }}</span>
                <br>
                <span>수료증 직인</span>
                <br>
                <img :src="`${API_BASE_URL}/${lecture.certificate_seal_image_path}`" alt="Seal Image" />
            </div>
        </div>

        <div class="layer_4">
            <p class="l3_top">수료정보</p>
            <span>산업안전 수료 기준</span><br>
            <label class="toggle_label">
                <span class="lecture_span toggle_slider" :class="{ 'active': lecture.isPracticeRequired8 }">
                    {{ lecture.isPracticeRequired8 ? 'Yes' : 'No' }}
                </span>
            </label>
            <hr>
            <span>반영비율</span>
            <br>
            <span class="span_50">중간평가</span> <span class="put_50">{{ lecture.midterm_evaluation_ratio }}%</span><br>
            <span class="span_50">최종평가</span> <span class="put_50">{{ lecture.final_evaluation_ratio }}%</span><br>
            <span class="span_50">과제</span> <span class="put_50">{{ lecture.task_evaluation_ratio }}%</span>
            <hr>
            <span>수료기준</span><br>
            <span class="span_51">진도율</span> <span class="put_50">{{ lecture.progress_rate }}%</span><br>
            <span class="span_51">중간평가(총점)</span> <span class="put_50">{{ lecture.midterm_score }}점 중 {{
                lecture.midterm_pass_score }}점 이상</span><br>
            <span class="span_51">최종평가(총점)</span> <span class="put_50">{{ lecture.final_score }}점 중 {{
                lecture.final_pass_score }}점 이상</span><br>
            <span class="span_51">과제</span> <span class="put_50">{{ lecture.task_score }}점 중 {{ lecture.task_pass_score }}점
                이상</span><br>
            <span class="span_51">반영비율을 적용한 총점</span> <span class="put_50">{{ lecture.total_pass_score }}점 이상 수료</span>
        </div>

        <div class="layer_3">
            <div class="layer_3_1">
                <p class="l3_top">평가</p>
                <p>중간평가 [문항수/배점]</p>
                <table>
                    <tr>
                        <td>진위형</td>
                        <td>{{ lecture.midterm_true_false_count }}</td>
                        <td>{{ lecture.midterm_true_false_score }}</td>
                        <td>객관식</td>
                        <td>{{ lecture.midterm_multiple_choice_count }}</td>
                        <td>{{ lecture.midterm_multiple_choice_score }}</td>
                        <td>단답형</td>
                        <td>{{ lecture.midterm_short_answer_count }}</td>
                        <td>{{ lecture.midterm_short_answer_score }}</td>
                        <td>서술형</td>
                        <td>{{ lecture.midterm_essay_count }}</td>
                        <td>{{ lecture.midterm_essay_score }}</td>
                    </tr>
                </table>

                <p>최종평가 [문항수/배점]</p>
                <table>
                    <tr>
                        <td>진위형</td>
                        <td>{{ lecture.final_true_false_count }}</td>
                        <td>{{ lecture.final_true_false_score }}</td>
                        <td>객관식</td>
                        <td>{{ lecture.final_multiple_choice_count }}</td>
                        <td>{{ lecture.final_multiple_choice_score }}</td>
                        <td>단답형</td>
                        <td>{{ lecture.final_short_answer_count }}</td>
                        <td>{{ lecture.final_short_answer_score }}</td>
                        <td>서술형</td>
                        <td>{{ lecture.final_essay_count }}</td>
                        <td>{{ lecture.final_essay_score }}</td>
                    </tr>
                </table>

                <p>과제 [문항수/배점]</p>
                <table>
                    <tr>
                        <td>진위형</td>
                        <td>{{ lecture.task_true_false_count }}</td>
                        <td>{{ lecture.task_true_false_score }}</td>
                        <td>객관식</td>
                        <td>{{ lecture.task_multiple_choice_count }}</td>
                        <td>{{ lecture.task_multiple_choice_score }}</td>
                        <td>단답형</td>
                        <td>{{ lecture.task_short_answer_count }}</td>
                        <td>{{ lecture.task_short_answer_score }}</td>
                        <td>서술형</td>
                        <td>{{ lecture.task_essay_count }}</td>
                        <td>{{ lecture.task_essay_score }}</td>
                    </tr>
                </table>
            </div>

            <div class="lecture_inform">
                <p class="l3_top">수료정보</p>
                <span>과정소개</span>
                <p class="txta">{{ lecture.course_introduction }}</p><br>
                <span>교육대상</span>
                <p class="txta">{{ lecture.target_audience }}</p><br>
                <span>교육목표</span>
                <p class="txta">{{ lecture.learning_objectives }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'LectureDetail',
    data() {
        return {
            lecture: {}
        };
    },
    methods: {
        async fetchLecture() {
            try {
                const courseId = this.$route.query.id;
                const token = localStorage.getItem('accessToken');
                const response = await axios.post(`${API_BASE_URL}/lecture/lecture/courses`, {
                    course_ids: [courseId],
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                if (response.data.length > 0) {
                    this.lecture = response.data[0];
                } else {
                    alert('해당 강의를 찾을 수 없습니다.');
                }
            } catch (error) {
                console.error('강의 상세 조회 중 에러 발생:', error);
                alert('강의 상세 조회 중 오류가 발생했습니다.');
            }
        },
    },
    mounted() {
        this.fetchLecture();
    },
};
</script>

<style scoped>@import "../../../css/lectureinsert.css";</style>
