<template>
    <div>
        <h2>학습자료 목록</h2>
        <button @click="insertdata()">추가하기</button>
        <table>
            <thead>
                <tr>
                    <th>번호</th>
                    <th>제목</th>
                    <th>작성자</th>
                    <th>등록일</th>
                    <th>조회수</th>
                    <th>수정</th>
                    <th>삭제</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(material, index) in materials" :key="material.id">
                    <td>{{ index + 1 }}</td>
                    <td><a :href="`/datadetail/${material.id}`">{{ material.title }}</a></td>
                    <td>{{ material.author }}</td>
                    <td>{{ formatDate(material.created_at) }}</td>
                    <td>{{ material.views }}</td>
                    <td><button @click="editMaterial(material.id)">수정</button></td>
                    <td><button @click="deleteMaterial(material.id)">삭제</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'StudyMaterialList',
    data() {
        return {
            materials: [],
            API_BASE_URL: process.env.VUE_APP_API_BASE_URL,
        };
    },
    mounted() {
        this.fetchMaterials();
    },
    methods: {
        insertdata() {
            this.$router.push({ name: 'data_insert' });
        },
        async fetchMaterials() {
            try {
                const response = await axios.get(`${this.API_BASE_URL}/data`);
                this.materials = response.data.data;
            } catch (error) {
                console.error('Error fetching materials:', error);
            }
        },
        editMaterial(id) {
            this.$router.push({ name: 'data_edit', params: { id } });
        },
        async deleteMaterial(id) {
            if (!confirm('정말로 이 학습자료를 삭제하시겠습니까?')) return;

            try {
                const response = await axios.delete(`${this.API_BASE_URL}/data/${id}`);
                if (response.data.success) {
                    this.materials = this.materials.filter(material => material.id !== id);
                    alert('학습자료가 성공적으로 삭제되었습니다.');
                } else {
                    alert('학습자료 삭제에 실패했습니다.');
                }
            } catch (error) {
                console.error('Error deleting material:', error);
                alert('학습자료 삭제 중 오류가 발생했습니다.');
            }
        },
        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false,
            };
            return new Date(dateString).toLocaleString('ko-KR', options);
        },
    },
};
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

button {
    padding: 5px 10px;
}
</style>
