<template>
    <div class="wrap">
        <p>롤링 배너 설정</p>
        <input type="file" ref="fileInput" @change="handleFileUpload">
        <button @click="uploadFile">업로드</button>
        <div>
            <table border="1" cellspacing="0">
                <thead>
                    <tr>
                        <th>NO</th>
                        <th>파일명</th>
                        <th>이미지</th>
                        <th>기타</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(file, index) in files" :key="file.id">
                        <td>{{ index + 1 }} 번째 메인배너 - [{{ file.id }}]</td>
                        <td>{{ file.filename }}</td>
                        <td><img :src="`${this.imagebaseurl}/${file.filepath}`" alt="미리보기" style="width: 100px;"></td>
                        <td>
                            <button @click="deleteFile(file.id)">삭제</button>
                            <button @click="editFile(index)">변경</button>
                            <button @click="moveUp(index)" :disabled="index === 0">↑</button>
                            <button @click="moveDown(index)" :disabled="index === files.length - 1">↓</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const IMAGE_BASE_URL = process.env.VUE_APP_IMAGE_BASE_URL;
export default {
    name: 'AdminPage',
    data() {
        return {
            selectedFile: null,
            files: [],
            editingIndex: null, // 파일 변경 시 사용할 인덱스
            images: [],
            imagebaseurl: "",
        };
    },
    methods: {
        handleFileUpload(event) {
            this.selectedFile = event.target.files[0];
            if (this.editingIndex !== null) {
                this.updateFile();
            }
        },
        async uploadFile() {
            if (!this.selectedFile) {
                alert('파일을 선택해주세요.');
                return;
            }

            const formData = new FormData();
            formData.append('file', this.selectedFile);

            try {
                const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data.success) {
                    alert('파일 업로드 성공!');
                    this.fetchFiles(); // 파일 업로드 후 파일 목록 갱신
                } else {
                    alert('파일 업로드 실패.');
                }
            } catch (error) {
                console.error('파일 업로드 에러:', error);
                alert('파일 업로드 중 에러가 발생했습니다.');
            }
        },
        async fetchFiles() {
            try {
                const response = await axios.get(`${API_BASE_URL}/upload/files`);
                if (response.data.success) {
                    this.files = response.data.data;
                    console.log(IMAGE_BASE_URL);
                    this.imagebaseurl = IMAGE_BASE_URL;

                } else {
                    alert('파일 목록 조회 실패.');
                }
            } catch (error) {
                console.error('파일 목록 조회 에러:', error);
                alert('파일 목록 조회 중 에러가 발생했습니다.');
            }
        },
        async deleteFile(id) {
            try {
                const response = await axios.delete(`${API_BASE_URL}/upload/files/${id}`);
                if (response.data.success) {
                    alert('파일 삭제 성공!');
                    this.fetchFiles(); // 파일 삭제 후 파일 목록 갱신
                } else {
                    alert('파일 삭제 실패.');
                }
            } catch (error) {
                console.error('파일 삭제 에러:', error);
                alert('파일 삭제 중 에러가 발생했습니다.');
            }
        },
        editFile(index) {
            this.editingIndex = index;
            this.$refs.fileInput.click();
        },
        async updateFile() {
            if (!this.selectedFile) {
                alert('파일을 선택해주세요.');
                return;
            }

            const formData = new FormData();
            formData.append('file', this.selectedFile);
            formData.append('id', this.files[this.editingIndex].id);

            try {
                const response = await axios.post(`${API_BASE_URL}/upload/update`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if (response.data.success) {
                    alert('파일 변경 성공!');
                    this.fetchFiles(); // 파일 변경 후 파일 목록 갱신
                    this.editingIndex = null; // 인덱스 초기화
                } else {
                    alert('파일 변경 실패.');
                }
            } catch (error) {
                console.error('파일 변경 에러:', error);
                alert('파일 변경 중 에러가 발생했습니다.');
            }
        },
        async moveUp(index) {
            if (index === 0) return;
            this.swapFiles(index, index - 1);
            await this.updateOrder();
        },
        async moveDown(index) {
            if (index === this.files.length - 1) return;
            this.swapFiles(index, index + 1);
            await this.updateOrder();
        },
        swapFiles(index1, index2) {
            const temp = this.files[index1];
            this.files.splice(index1, 1, this.files[index2]);
            this.files.splice(index2, 1, temp);
        },
        async updateOrder() {
            try {
                const orderPromises = this.files.map((file, index) => {
                    return axios.post(`${API_BASE_URL}/upload/reorder`, {
                        id: file.id,
                        order: index
                    });
                });

                await Promise.all(orderPromises);
                alert('파일 순서가 성공적으로 업데이트되었습니다.');
            } catch (error) {
                console.error('파일 순서 업데이트 에러:', error);
                alert('파일 순서 업데이트 중 에러가 발생했습니다.');
            }
        }
    },
    mounted() {
        this.fetchFiles(); // 컴포넌트가 마운트될 때 파일 목록을 조회
    }
};
</script>

<style scoped>
.wrap {
    padding: 20px;
}

table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
}

th,
td {
    padding: 10px;
    text-align: left;
}

th {
    background-color: #f4f4f4;
}
</style>
