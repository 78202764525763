<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import AdminLayout from '@/layouts/AdminLayout.vue';

export default {
  name: 'App',
  computed: {
    layout() {

      const currentRoute = this.$route;
      return currentRoute.meta.layout === 'admin' ? AdminLayout : DefaultLayout;
    }
  }
}
</script>
