// CMD> npm install vue-router@next --save
import { createWebHistory, createRouter } from "vue-router";


import AdminPage from '@/components/admin/AdminPage.vue'
import AdminPage2 from '@/components/admin/AdminPage2.vue'
import AdminPage3 from '@/components/admin/AdminPage3.vue'
import AdminPage4 from '@/components/admin/AdminPage4.vue'

import noticeinsert from '@/components/admin/notice/noticeinsert.vue'
import noticedetail from '@/components/admin/notice/noticedetail.vue'
import noticeedit from '@/components/admin/notice/noticeedit.vue'
import noticelist from '@/components/admin/notice/noticelist.vue'

import datainsert from '@/components/admin/data/datainsert.vue'
import datadetail from '@/components/admin/data/datadetail.vue'
import dataedit from '@/components/admin/data/dataedit.vue'
import datalist from '@/components/admin/data/datalist.vue'


import faqinsert from '@/components/admin/faq/faqinsert.vue'
import faqlist from '@/components/admin/faq/faqlist.vue'
import faqedit from '@/components/admin/faq/faqedit.vue'
import FaqDetail from '@/components/admin/faq/FaqDetail.vue'


import lectureinsert from '@/components/admin/lecture/lectureinsert.vue'
import lecturelist from '@/components/admin/lecture/lecturelist.vue'
import lecturedetail from '@/components/admin/lecture/lecturedetail.vue'
import lecturecontent from '@/components/admin/lecture/lecturecontent.vue'
import lectureexam from '@/components/admin/lecture/lectureexam.vue'
import edu_lecture from '@/components/admin/edu_lecture/edu_lecture.vue'
import game from '@/components/admin/etc/game.vue'
import trade from '@/components/trading/trade.vue'

const adminRoutes = [
    { path: '/trade', name: "trade", component: trade, meta: { layout: 'admin' } },
    { path: '/game', name: "game", component: game, meta: { layout: 'admin' } },
    { path: '/', name: 'AdminPage', component: AdminPage, meta: { layout: 'admin' } },
    { path: '/AdminPage2', name: 'AdminPage2', component: AdminPage2, meta: { layout: 'admin' } },
    { path: '/AdminPage3', name: 'AdminPage3', component: AdminPage3, meta: { layout: 'admin' } },
    { path: '/AdminPage4', name: 'AdminPage4', component: AdminPage4, meta: { layout: 'admin' } },
    { path: '/noticeinsert', name: 'notice_insert', component: noticeinsert, meta: { layout: 'admin' } },
    { path: '/noticedetail/:id', name: 'notice_detail', component: noticedetail, meta: { layout: 'admin' } },
    { path: '/noticeedit/:id', name: 'notice_edit', component: noticeedit, meta: { layout: 'admin' } },
    { path: '/noticelist', name: 'notice_list', component: noticelist, meta: { layout: 'admin' } },


    { path: '/datainsert', name: 'data_insert', component: datainsert, meta: { layout: 'admin' }, },



    { path: '/datadetail/:id', name: 'data_detail', component: datadetail, meta: { layout: 'admin' } },
    { path: '/dataedit/:id', name: 'data_edit', component: dataedit, meta: { layout: 'admin' } },
    { path: '/datalist', name: 'data_list', component: datalist, meta: { layout: 'admin' } },
    { path: '/faqinsert', name: 'faqinsert', component: faqinsert, meta: { layout: 'admin' } },
    { path: '/faqlist', name: 'faqlist', component: faqlist, meta: { layout: 'admin' } },
    { path: '/faqedit/:id', name: 'faqedit', component: faqedit, meta: { layout: 'admin' } },
    { path: '/FaqDetail/:id', name: 'FaqDetail', component: FaqDetail, meta: { layout: 'admin' } },

    { path: '/lectureinsert', name: 'LectureInsert', component: lectureinsert, meta: { layout: 'admin' }, },
    { path: '/edu_lecture', name: 'edu_lecture', component: edu_lecture, meta: { layout: 'admin' }, },
    { path: '/lecturelist', name: 'lecturelist', component: lecturelist, meta: { layout: 'admin' }, },
    { path: '/lectureexam', name: 'lectureexam', component: lectureexam, meta: { layout: 'admin' }, },
    { path: '/lecturedetail', name: 'lecturedetail', component: lecturedetail, meta: { layout: 'admin' }, },
    { path: '/lecturecontent', name: 'lecturecontent', component: lecturecontent, meta: { layout: 'admin' }, },

];

const router = createRouter({
    history: createWebHistory(),
    routes: adminRoutes,
});




export default router;