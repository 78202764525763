<template>
    <div>
        <h1>과정 리스트</h1>
        <input v-model="searchKeyword" @input="fetchLectures" placeholder="검색어 입력" />
        <ul>
            <li v-for="lecture in lectures" :key="lecture.id" class="lecture-item">
                <router-link :to="{ name: 'lecturedetail', query: { id: lecture.id } }">
                    <img v-if="lecture.course_image" :src="`${API_BASE_URL}/uploads/${lecture.course_image}`"
                        alt="Course Image" class="lecture-image" />
                    <h2>{{ lecture.course_name }}</h2>
                    <p>{{ lecture.course_description }}</p>
                    <p>등록일: {{ formatDate(lecture.created_at) }}</p>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'LectureList',
    data() {
        return {
            lectures: [],
            searchKeyword: '',
        };
    },
    methods: {
        async fetchLectures() {
            try {
                const response = await axios.get(`${API_BASE_URL}/lecture`, {
                    params: {
                        search_keyword: this.searchKeyword,
                    },
                });
                this.lectures = response.data.data;
            } catch (error) {
                console.error('과정 목록 조회 중 에러 발생:', error);
                alert('과정 목록 조회 중 오류가 발생했습니다.');
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString();
        }
    },
    mounted() {
        this.fetchLectures();
    },
};
</script>

<style scoped>
.lecture-item {
    list-style: none;
    margin: 20px 0;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: box-shadow 0.3s;
}

.lecture-item:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.lecture-image {
    max-width: 200px;
    margin-right: 20px;
}
</style>
