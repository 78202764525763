<template>
    <div class="register">
        <h2>Register</h2>
        <form @submit.prevent="register">
            <div>
                <label for="companynumber">소속회사 사업자 등록번호</label>
                <td>
                    <input type="text" v-model="companynumber1" required>
                    <input type="text" v-model="companynumber2" required>
                    <input type="text" v-model="companynumber3" required>
                </td>
            </div>
            <div>
                <label for="userid">아이디</label>
                <input type="text" v-model="userid" required>
            </div>
            <div>
                <label for="userpw">비밀번호</label>
                <input type="password" v-model="userpw" required>
            </div>
            <div>
                <label for="userpw2">비밀번호 확인</label>
                <input type="password" v-model="userpw2" required>
            </div>
            <div>
                <label for="username">이름</label>
                <input type="text" v-model="username" required>
            </div>
            <div>
                <label for="birthday1">생년월일</label>
                <select name="member_birthday1" v-model='birthday1'>
                    <option v-for="year in years" :key="year" :value="year">{{ year }}년</option>
                </select>
                <select name="member_birthday2" v-model='birthday2'>
                    <option v-for="month in months" :key="month" :value="month">{{ month }}월</option>
                </select>
                <select name="member_birthday3" v-model='birthday3'>
                    <option v-for="day in days" :key="day" :value="day">{{ day }}일</option>
                </select>
            </div>
            <div>
                <label for="gender">성별</label>
                <td class="none">
                    <input type="radio" name="member_gender" v-model="gender" id="member_gender_M" value="M"> <label
                        for="member_gender_M">남</label>
                    <span class="pl25"></span>
                    <input type="radio" name="member_gender" v-model="gender" id="member_gender_F" value="F"> <label
                        for="member_gender_F">여</label>
                </td>
            </div>
            <div>
                <label for="phone_number">휴대전화</label>
                <input type="text" v-model="phonenumber1" required>
                <input type="text" v-model="phonenumber2" required>
                <input type="text" v-model="phonenumber3" required>
            </div>
            <div>
                <label for="email">이메일</label>
                <input type="email" v-model="email" required>
            </div>
            <button type="submit">Register</button>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
export default {
    data() {
        return {
            companynumber1: '',
            companynumber2: '',
            companynumber3: '',
            userid: '',
            userpw: '',
            userpw2: '',
            username: '',
            birthday1: '2000',
            birthday2: '07',
            birthday3: '08',
            gender: 'M',
            phonenumber1: '',
            phonenumber2: '',
            phonenumber3: '',
            email: '',
            years: Array.from({ length: (new Date().getFullYear() - 1929) }, (_, i) => 1930 + i),
            months: Array.from({ length: 12 }, (_, i) => String(i + 1).padStart(2, '0')),
            days: Array.from({ length: 31 }, (_, i) => String(i + 1).padStart(2, '0'))
        }
    },
    methods: {
        async register() {
            try {
                const birthday = `${this.birthday1}-${this.birthday2}-${this.birthday3}`;
                const phonenumber = `${this.phonenumber1}-${this.phonenumber2}-${this.phonenumber3}`;
                const companynumber = `${this.companynumber1}-${this.companynumber2}-${this.companynumber3}`;
                const response = await axios.post(`${API_BASE_URL}/member/register`, {
                    companynumber,
                    userid: this.userid,
                    password: this.userpw,
                    username: this.username,
                    birthday,
                    gender: this.gender,
                    phonenumber,
                    email: this.email
                });
                alert(response.data.msg);
            } catch (error) {
                alert('Error registering user: ' + error.response.data.msg);
            }
        }
    }
}
</script>

<style scoped>
.register {
    max-width: 300px;
    margin: 0 auto;
}
</style>
