<template>
    <div class="protected">
        <h2>Protected Page</h2>
        <p>이 페이지는 인증된 사용자만 접근할 수 있습니다.</p>
    </div>
</template>

<script>

export default {
    name: 'TopMenu',
    beforeRouteEnter(to, from, next) {
        const token = localStorage.getItem('token');
        if (!token) {
            next({ name: 'mLogin' });
        } else {
            next();
        }
    }
}
</script>

<style scoped>
.protected {
    max-width: 300px;
    margin: 0 auto;
}
</style>
