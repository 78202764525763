<template>
    <div class="subbg" @mousemove="notifyParent" @keydown="notifyParent" @click="notifyParent">
        <div class="sub_title"><span>{{ tiltetext }}</span></div>
        <div class="sub_category">
            <div class="sc_home">Home</div>
            <ul>
                <li v-for="item in ptitem" :key="item.id" :style="liStyle" :class="{ active: isActive(item.id) }">
                    <a :href="item.link">{{ item.title }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'sMENU',
    data() {
        return {
            par_id: "",
            par_id2: "",
            subNavigationItems: [],
            tiltetext: "",
            navigationItems: [],
            ptitem: [],
            ptlenght: "",
            currentParentId: null, // 현재 parent ID를 저장하는 변수
            lecturecode: "",
            filteredItems: [],
      
        };
    },
    computed: {
        liStyle() {
            return {
                width: `calc(90% / ${this.ptlenght})`
            };
        }
    },
    mounted() {
        this.fetchSubNavigationItems();
        this.setCurrentParentId(); // 페이지 로드 시 currentParentId를 설정
        this.fetchNavigationItems();
    },
    methods: {
        async fetchNavigationItems() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                this.lecturecode = urlParams.get('lecture');
                console.log(this.lecturecode,"lcode");
        const response = await axios.get(`${API_BASE_URL}/navigation`);
        if (response.data.success) {
            // 전체 데이터를 navigationItems에 저장한 후
            this.navigationItems = response.data.data;
            console.log(this.navigationItems,"asdd")

            // id가 5인 항목만 필터링하여 저장
            this.filteredItems = this.navigationItems.filter(item => item.id === Number(this.lecturecode));
            console.log(this.filteredItems, "filter");
            this.tiltetext = this.filteredItems[0].title
          
          
        } else {
            alert('네비게이션 항목 조회 실패.');
        }
    } catch (error) {
        console.error('네비게이션 항목 조회 에러:', error);
        alert('네비게이션 항목 조회 중 에러가 발생했습니다.');
    }
},
        check() {
            console.log("asd");  
        },
        async ftcheck() {
            try {
                const response = await axios.get(`${API_BASE_URL}/navigation/sub-navigation-ptid`, {
                    params: { parentId2: this.par_id2 }
                });
                if (response.data.success) {
                    this.ptitem = response.data.data;
                    this.ptlenght = this.ptitem.length;
                    console.log(this.ptitem, "ptitemasdsad");
                    console.log(this.ptlenght);
                } else {
                    alert('데이터 조회 실패');
                }
            } catch (error) {
                console.error('데이터 조회 중 에러 발생:', error);
                alert('데이터 조회 중 에러가 발생했습니다.');
            }
        },
        async fetchSubNavigationItems() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                this.par_id = urlParams.get('parent');
                console.log(this.par_id, "par_id");

                const response = await axios.get(`${API_BASE_URL}/navigation/sub-navigation-id`, {
                    params: { parentId: this.par_id }
                });
                if (response.data.success) {
                    this.subNavigationItems = response.data.data;
            
                    console.log(this.tiltetext);
                    this.par_id2 = this.subNavigationItems[0].parent_id;
                    console.log(this.par_id2);
                    this.ftcheck()
                } else {
                    alert('데이터 조회 실패');
                }
            } catch (error) {
                console.error('데이터 조회 중 에러 발생:', error);
                alert('데이터 조회 중 에러가 발생했습니다.');
            }
        },
        setCurrentParentId() {
            const urlParams = new URLSearchParams(window.location.search);
            this.currentParentId = urlParams.get('parent');
        },
        isActive(itemId) {
            return this.currentParentId === itemId.toString(); // 현재 활성화된 아이템을 식별
        },
        homego() {
            this.$router.push({ name: 'MainPage' });
        },
        notifyParent() {
            this.$emit('user-activity');
        }
    },
};
</script>

<style>
@import "../../css/common.css";
@import "../../css/subMenu.css";
@import "../../css/pcsize.css";

.sb_wrap {
    width: 100%;
    height: 125px;
    margin: 0 auto;
    border-bottom: 1px solid #ccc;
}

.subbg {
    background-image: url("../../assets/subbg05.jpg");
    height: 170px;
    position: relative;
}

.sub_title {
    color: rgb(41, 20, 134);
    font-weight: bold;
    font-size: 27px;
    text-align: center;
    width: 100%;
    line-height: 110px;
}

.sub_category {
    position: absolute;
    bottom: -20px;
    background-color: aliceblue;

    left: 50%;
    transform: translate(-50%);
    height: 80px;
}

.sc_home {
    width: 10%;
    box-sizing: border-box;
    float: left;
    height: 100%;
    line-height: 80px;
    text-align: center;
    border-right: 1px solid #e9e8e8;
    background-color: #666666;
    color: rgb(255, 255, 255);
}

.sub_category ul {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

.sub_category ul li {
    list-style-type: none;
    display: inline-block;
    padding: 0px 10px;
    border-right: 1px solid #e9e8e8;
    height: 100%;
    line-height: 80px;
    text-align: center;
    box-sizing: border-box;
}

/* 활성화된 항목에 대한 스타일 */
.sub_category ul li.active {


}

.sub_category ul li.active a {
    color: rgb(63, 60, 240);
    font-weight: bold;
}
</style>
