// CMD> npm install vue-router@next --save
import { createWebHistory, createRouter } from "vue-router";

import subMenu from '@/components/section/subMenu.vue'
import boardsearch from '@/components/section/boardsearch.vue'
import TopMenu from '@/components/section/TopMenu.vue'
import mLogin from '@/components/member/mLogin.vue'
import mJoin from '@/components/member/mJoin.vue'
import mcheck from '@/components/member/mcheck.vue'
import MainPage from '@/components/section/MainPage.vue'
import CarouselPage from '@/components/section/CarouselPage.vue'
import navMenu from '@/components/section/navMenu.vue'
import noticeall from '@/components/notice/noticeall.vue'

import noticedetail from '@/components/notice/noticedetail.vue'
import subbar from '@/components/section/subbar.vue'
import subbar_lecture from '@/components/section/subbar_lecture.vue'
import Footer from '@/components/section/footer.vue'
import warn from '@/components/warn/warn.vue'
import faq from '@/components/faq/faq.vue'
import data from '@/components/data/data.vue'
import dataall from '@/components/data/dataall.vue'

import datadetail from '@/components/data/datadetail.vue'
import lecturelist from '@/components/lecture/lecturelist.vue'
import lecturesearch from '@/components/section/lecturesearch.vue'
import edu_process from '@/components/company/edu_process.vue'
import introduce from '@/components/company/introduce.vue'
import hello from '@/components/company/hello.vue'

import vision from '@/components/company/vision.vue'
import organization from '@/components/company/organization.vue'
import address from '@/components/company/address.vue'
import my_edu from '@/components/mypage/my_edu.vue'
import my_edu_lecture from '@/components/mypage/my_edu_lecture.vue'

import trade from '@/components/trading/trade.vue'





const routes = [

    { path: '/trade', name: "trade", component: trade },
    { path: '/boardsearch', name: "boardsearch", component: boardsearch },
    { path: '/subMenu', name: "subMenu", component: subMenu },
    { path: '/TopMenu', name: "TopMenu", component: TopMenu },
    { path: '/mLogin', name: "mLogin", component: mLogin },
    { path: '/mJoin', name: "mJoin", component: mJoin },
    { path: '/mcheck', name: 'mcheck', component: mcheck },
    { path: '/subbar', name: 'subbarname', component: subbar },
    { path: '/subbar_lecture', name: 'subbar_lecture', component: subbar_lecture },
    { path: '/Footer', name: 'Footer', component: Footer },
    { path: '/', name: 'MainPage', component: MainPage },
    { path: '/CarouselPage', name: 'CarouselPage', component: CarouselPage },
    { path: '/navMenu', name: 'navMenu', component: navMenu },
    { path: '/support/notice', name: 'Frinotice', component: noticeall },

    { path: '/support/noticedetail', name: 'NoticeDetail', component: noticedetail },
    { path: '/support/warn', name: 'warn', component: warn },
    { path: '/support/faq', name: 'faq', component: faq },
    { path: '/support/data_sub', name: 'data', component: data },
    { path: '/support/data', name: 'dataall', component: dataall },
    { path: '/support/datadetail', name: 'datadetail', component: datadetail },
    { path: '/lecture/lecturelist', name: 'lecturelist', component: lecturelist },
    { path: '/lecturesearch', name: 'lecturesearch', component: lecturesearch },

    { path: '/company/edu_process', name: 'edu_process', component: edu_process },
    { path: '/company/introduce', name: 'InTroduce', component: introduce },
    { path: '/company/hello', name: 'Hello', component: hello },
    { path: '/company/vision', name: 'Vision', component: vision },
    { path: '/company/organization', name: 'Organization', component: organization },
    { path: '/company/address', name: 'Address', component: address },

    { path: '/mypage/my_edu', name: 'my_edu', component: my_edu },
    { path: '/mypage/my_edu_lecture', name: 'my_edu_lecture', component: my_edu_lecture },


];

const router = createRouter({
    history: createWebHistory(),
    routes,
});




export default router;