
<template>
    <div>
        <subbar />
        <div class="inner_warn">

            <div class="guide01">
                <table class="guide01tb">
                    <tbody>
                        <tr>
                            <th><img src="../../assets/WarnImage/icon19.png"></th>
                            <td><img src="../../assets/WarnImage/icon19.png" class="mobileshow">
                                <p class="guidett">자동 수강, 제출 프로그램방지 OTP 시스템 안내</p>
                                <p class="guidett_1"><span>첫 차시</span>와 이후 <span>8차시</span> 단위로 <span>MOTP 인증</span> 필요<br>
                                    <span>중간평가, 최종평가, 과제제출</span> 페이지 입장 전 <span>1번씩 인증</span> 필요
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <th><img src="../../assets/WarnImage/icon20.png"></th>
                            <td><img src="../../assets/WarnImage/icon20.png" class="mobileshow">
                                <p class="guidett">본인인증 안내</p>
                                <p class="guidett_1">교육진행은 본인 인증을 위해 다음과 같이 페이지에서 <span>휴대폰인증 혹은 아이핀 인증</span>을 필요로 합니다.<br>
                                    · <span>최초 회원가입 시</span><br>

                                    · <span>최초 과정교육 시작 시</span></p>
                            </td>
                        </tr>
                        <tr>
                            <th><img src="../../assets/WarnImage/icon21.png"></th>
                            <td><img src="../../assets/WarnImage/icon21.png" class="mobileshow">
                                <p class="guidett">사업주 지원 훈련 규정 일일진도제한 안내</p>
                                <p class="guidett_1">사업주 지원 훈련 규정 상 하루 <span>최대 8차시까지만 수강</span>이 가능합니다.</p>
                            </td>
                        </tr>

                        <tr>
                            <th><img src="../../assets/WarnImage/icon22.png"></th>
                            <td><img src="../../assets/WarnImage/icon22.png" class="mobileshow">
                                <p class="guidett">수료기준 안내</p>
                                <p class="guidett_1">모든 과정의 수료기준은 <span>진도율 80%이상, 총점 60점 이상</span> 입니다.<br>
                                    평가 항목이나 반영비율은 <span>과정별 상이할 수 </span> 있습니다.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <th><img src="../../assets/WarnImage/icon23.png"></th>
                            <td><img src="../../assets/WarnImage/icon23.png" class="mobileshow">
                                <p class="guidett">강의 진행시 주의사항</p>
                                <p class="guidett_1">중간평가는 강의 전체차시 기준 50%이상 되어야 응시가능합니다.<br>
                                    모든 수강과정의 최종 평가응시와 과제제출은 진도율이 80%이상 되어야 가능합니다.<br>


                                    최종평가의 경우 제한시간이 있으며 접속종료 등의 상태에서도 중간없이 계속 흘러가게 됩니다.<br>
                                    사업주 환급교육 과락자 재응시는 교육 종료 후 가능하며, 종료일+7일 이내에 실시하셔야 합니다.<br>
                                    평가 미응시로 인한 미수료자는 재응시가 불가능합니다.
                                </p>
                            </td>
                        </tr>

                        <tr>
                            <th><img src="../../assets/WarnImage/icon24.png"></th>
                            <td><img src="../../assets/WarnImage/icon24.png" class="mobileshow">
                                <p class="guidett">과제 제출시 베낀답안 기준 및 처리 안내</p>

                                <p class="guidett_1"><span>베낀답안 기준</span><br>
                                    · 과제에 있어 타인의 답안을 그대로 복사하거나 일부 문구만을 수정하여 제출한 답안을 말한다.(베낀답안 적용 대상 : 과제(레포트))<br>
                                    · 베낀답안 적용기준<br>
                                    1. 기본 정보나 개요를 묻는 문제, 답안이 동일할 수밖에 없는 계산형, 실습형, 학습내용에서 발췌하는 내용은 적용하지 않는다.<br>
                                    (단, 문제에서 제시되지 않은 조건 예를 들어, 도형의 위치, 선 굵기 등이 일치하는 경우는 베낀답안 처리대상에 포함된다.)<br>
                                    2. 문항별 및 전체 답안이 90%이상 동일한 경우<br>
                                    3. 오타, 띄어쓰기, 특수문자 등 내용이 유사하거나 동일한 경우<br>
                                    4. 단락의 앞뒤 구성을 바꿔서 동일한 내용의 답안을 제출한 경우<br>
                                    5. 사고력 측정형, 사례 제시형, 현업적용형과 같은 문제 유형의 답안이 80%이상 동일한 경우<br><br>


                                    <span>베낀답안 처리 방침</span><br>
                                    · 해당 문항 및 과제 0점 처리하며, 제출자와 답안 제공자 모두 0점 처리된다.<br>
                                    · 베낀답안 처리 절차<br>
                                    1. 베낀답안체크 프로그램을 가동한다.<br>
                                    2. 첨삭을 진행할 시 베낀답안률이 90% 이상인 학습자를 중점적으로 1차 확인을 한다.<br>
                                    3. 베낀답안으로 의심되는 학습자의 경우 베낀답안의심여부에 체크하고, 채점기준에 맞게 첨삭을 진행한 후 점수를 부여한다.<br>
                                    4. 첨삭완료 후 교육운영자가 2차 확인을 한다.<br>
                                    5. 베낀답안이 의심되는 학습자에게 통보하고, 베낀답안 여부를 최종 확인한다.<br>
                                    6. 베낀답안인 경우 교육운영자가 해당 문항 및 과제에 대해 0점 처리한다.<br>


                                </p>
                            </td>
                        </tr>

                        <tr>
                            <th><img src="../../assets/WarnImage/icon24_1.png"></th>
                            <td><img src="../../assets/WarnImage/icon24_1.png" class="mobileshow">
                                <p class="guidett">부정훈련 금지 </p>

                                <p class="guidett_1"><span>부정훈련이란?</span><br>
                                    · 타인의 훈련과정을 대신 수강하거나, 평가를 대신 응시, 베낀 답안을 제출하는 것을 의미하며, 부정훈련 적발시 사업주 뿐만 아니라 교육기관, 훈련생 모두
                                    처벌
                                    대상<br>

                                </p>
                                <p class="guidett_1"><span>부정훈련 적발 기준</span><br>
                                    · 자체 모니터링 및 부정훈련 신고센터를 통해 신고된 건 중 아래 내용에 해당되는 훈련일 경우 부정훈련으로 간주<br>
                                    1. 연속된 시간에 동일 IP, 동일 PC에서 평가 제출시<br>
                                    2. 접속자의 FDS 정보가 동일한 경우<br>
                                    3. 사업자정보가 다르지만 동일한 IP에서 수강시<br>
                                    4. 사업자정보가 다르지만 동일한 IP에서 평가시<br>


                                    <span>부정훈련 적발 시 처리</span><br>

                                    1. 부정훈련 적발시 진도율 및 평가 점수에 상관없이 미수료로 처리<br>
                                    2. 부정훈련 적발시 재응시 불가능<br>
                                    3. 사업주가 공모하여 부정훈련에 가담할 시, 해당 사업주에게는 향후 교육원의 훈련 참여를 일절 제한<br>


                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
//import axios from 'axios';
//const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
import subbar from '../../components/section/subbar.vue';
export default {
    name: 'MenuwarnPage',
    data() {
        return {

        };
    },
    components: {
        subbar,


    },
    methods: {



    },


};
</script>
<style>
@import "../../css/warn.css";
@import "../../css/pcsize.css";

.mobileshow {
    display: none;
}
</style>
