<template>
    <div class="psize">
        <!-- 공지사항 리스트 테이블 -->
        <table class="tb_1" cellpadding="0" cellspacing="0" style="width: 90%; box-sizing: border-box; text-align: center;">
            <colgroup>
                <col width="7%" />
                <col width="15%" />
                <col width="30%" />
                <col width="12%" />
                <col width="18%" />
                <col width="12%" />
            </colgroup>
            <thead>
                <tr class="tb_2">
                    <td>번호</td>
                    <td>구분</td>
                    <td>제목</td>
                    <td>작성자</td>
                    <td>등록일</td>
                    <td>조회수</td>
                </tr>
            </thead>
            <tbody>
                <tr class="tb_3" v-for="(item) in paginatedItems" :key="item.id">
                    <td>{{ item.displayNumber }}</td>
                    <td>{{ getCategoryTitle(item.category) }}</td>
                    <td><a :href="`/support/noticedetail?id=${item.id}`">{{ item.title }}</a></td>
                    <td>{{ item.author }}</td>
                    <td>{{ item.formattedDate }}</td>
                    <td>{{ item.views }}</td>
                </tr>
                <tr v-if="paginatedItems.length === 0">
                    <td colspan="6">조회된 게시글이 없습니다.</td>
                </tr>
            </tbody>
        </table>
        <!-- 페이지네이션 버튼 -->
        <div class="btn_page">
            <button class="backbtn" @click="changePage(currentPage - 1)" :disabled="currentPage === 1">이전</button>
            <button v-for="page in totalPages" :key="page" @click="changePage(page)"
                :class="{ active: currentPage === page }">
                {{ page }}
            </button>
            <button class="nextbtn" @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">다음</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export default {
    name: 'FriNotice',
    props: {
        filteredItems: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            currentPage: 1,
            pageSize: 10,
            catelist: [], // 카테고리 리스트 데이터를 저장할 배열
            parentid: "",
        };
    },
    computed: {
        paginatedItems() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            const totalItems = this.filteredItems.length;

            return this.filteredItems.slice(start, end).map((item, index) => {
                return {
                    ...item,
                    displayNumber: totalItems - (start + index),
                    formattedDate: this.formatDate(item.created_at),
                    categoryTitle: this.getCategoryTitle(item.category)
                };
            });
        },
        totalPages() {
            return Math.ceil(this.filteredItems.length / this.pageSize);
        }
    },
    methods: {
        async fetchCategories() {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                this.parentid = urlParams.get('parent');  // URL 쿼리에서 'parent' 파라미터 값을 가져옴
                const response = await axios.get(`${API_BASE_URL}/navigation/sub2-navigation`, {
                    params: { parentId: this.parentid }
                });

                if (response.data.success) {
                    this.catelist = response.data.data; // 카테고리 데이터를 catelist에 저장
                } else {
                    alert('카테고리 데이터를 불러오는 데 실패했습니다.');
                }
            } catch (error) {
                console.error('카테고리 데이터를 불러오는 중 에러가 발생했습니다.', error);
                alert('카테고리 데이터를 불러오는 중 에러가 발생했습니다.');
            }
        },
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        formatDate(dateString) {
            const options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            };
            return new Date(dateString).toLocaleString('ko-KR', options).replace(/\./g, '-');
        },
        getCategoryTitle(categoryId) {
            const category = this.catelist.find(cat => cat.num === Number(categoryId)); // catelist에서 타이틀 찾기
            return category ? category.title : categoryId;
        }
    },
    mounted() {
        this.fetchCategories(); // 컴포넌트가 마운트될 때 카테고리 데이터 가져오기
    }
};
</script>

<style scoped>
/* 테이블 스타일 */

@import "../../css/notice.css";
@import "../../css/frinotice.css";
@import "../../css/pcsize.css";
</style>
